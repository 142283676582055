import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import StoresContext from '../../../Stores';
import modals from '../../../utils/modals';

const columns = [
  {
    key: 'name',
    label: 'Nombre',
    sortable: true
  },
  {
    key: 'portfolios',
    label: 'Numero de portafolios',
    sortable: true,
    template: (cell, row) => cell.length
  }
];

const deleteAction = async ({ row, clients, mutate }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Cliente',
    'Estas seguro que deseas borrar este Cliente?'
  );
  if (confirmed) {
    await clients.delete(row);
    mutate();
  }
};

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir',
      onClick: ({ row, navigate }) =>
        navigate(`/stocks/clients/${row.id}/portfolios`)
    },
    {
      key: 'dividends',
      label: ({ row }) => `Dividendos de ${row.name}`,
      onClick: ({ row, navigate }) =>
        navigate(`/stocks/clients/${row.id}/dividends`)
    },
    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreateClientButton() {
  return (
    <LinkAddButton to="/stocks/clients/create">Crear Cliente</LinkAddButton>
  );
}

function Clients() {
  const navigate = useNavigate();
  const { clients } = useContext(StoresContext);
  const { data, isLoading, isEmpty, mutate } = useAPI('/stocks');

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, navigate, clients, mutate });

  const showTable = !isLoading && !isEmpty;

  const handleRowClick = (evt, row) => {
    navigate(`/stocks/clients/${row.id}/portfolios`);
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Clientes"
        description="Un listado de clientes, haga click en uno para ver y manejar sus portafolios, o crea un cliente nuevo haciendo click en el boton."
      >
        {!isLoading && data.length > 0 && <CreateClientButton />}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateClientButton />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          striped
          hover
        />
      )}
    </div>
  );
}

export default Clients;
