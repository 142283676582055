/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  MenuIcon,
  OfficeBuildingIcon,
  CurrencyDollarIcon,
  ChartBarIcon,
  XIcon
} from '@heroicons/react/outline';

import { Routes, Route, Link, NavLink } from 'react-router-dom';

import Clients from './Pages/Clients';
import TDCs from './Pages/TDCs';
import INPCs from './Pages/INPCs';
import Portfolios from './Pages/Portfolios';
import CreateClient from './Pages/CreateClient';
import CreatePortfolio from './Pages/CreatePortfolio';
import StockRecords from './Pages/StockRecords';
import CreateStockRecord from './Pages/CreateStockRecord';

import DividendsList from './Pages/DividendsList';
import PortfolioDividendsList from './Pages/PortfolioDividendsList';
import CreateDividend from './Pages/CreateDividend';
import StockRecordDetails from './Pages/StockRecordDetails';
import ClientDividendList from './Pages/ClientDividendList';

import StoresContext from '../../Stores';

const navigation = [
  {
    name: 'Clientes',
    href: '/stocks/clients',
    icon: OfficeBuildingIcon,
    current: true
  },
  {
    name: `Tipo de Cambio`,
    href: '/stocks/tdcs',
    icon: CurrencyDollarIcon,
    current: false
  },
  {
    name: `Índice Nacional de Precios al Consumidor`,
    href: '/stocks/inpcs',
    icon: ChartBarIcon,
    current: false
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const { session } = useContext(StoresContext);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={(navData) =>
                        classNames(
                          navData.isActive
                            ? 'bg-indigo-800 text-white'
                            : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )
                      }
                      active
                    >
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-indigo-800 p-4">
                <span className="flex-shrink-0 w-full group block">
                  <div className="flex">
                    <div className="ml-3">
                      <button
                        type="button"
                        className="text-base font-medium text-white"
                        onClick={session.logout}
                      >
                        logout
                      </button>
                    </div>
                    <div className="grow" />
                    <div className="ml-3">
                      <Link to="/" className="text-sm font-medium text-white">
                        Apps
                      </Link>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="print:hidden  flex-1 flex flex-col min-h-0 bg-indigo-700">
          <div className="flex-1 flex flex-col pt-0 pb-4 overflow-y-auto">
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={(navData) =>
                    classNames(
                      navData.isActive
                        ? 'bg-indigo-800 text-white'
                        : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )
                  }
                >
                  <item.icon
                    className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-indigo-800 p-4">
            <span className="flex-shrink-0 w-full group block">
              <div className="flex">
                <div className="ml-3">
                  <button
                    type="button"
                    className="text-sm font-medium text-white"
                    onClick={session.logout}
                  >
                    logout
                  </button>
                </div>
                <div className="grow" />
                <div className="ml-3">
                  <Link to="/" className="text-sm font-medium text-white">
                    Apps
                  </Link>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="print:hidden sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="h-screen">
          <Routes>
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/create" element={<CreateClient />} />
            <Route path="/tdcs" element={<TDCs />} />
            <Route path="/inpcs" element={<INPCs />} />
            <Route
              path="/clients/:clientId/portfolios"
              element={<Portfolios />}
            />
            <Route
              path="/clients/:clientId/portfolios/create"
              element={<CreatePortfolio />}
            />
            <Route
              path="/clients/:clientId/portfolios/:portfolioId/stockrecords"
              element={<StockRecords />}
            />
            <Route
              path="/clients/:clientId/portfolios/:portfolioId/stockrecords/create"
              element={<CreateStockRecord />}
            />
            <Route
              path="/clients/:clientId/portfolios/:portfolioId/stockrecords/:stockRecordId/dividends"
              element={<DividendsList />}
            />
            <Route
              path="/clients/:clientId/portfolios/:portfolioId/stockrecords/:stockRecordId/dividends/create"
              element={<CreateDividend />}
            />
            <Route
              path="/clients/:clientId/portfolios/:portfolioId/dividends"
              element={<PortfolioDividendsList />}
            />

            <Route
              path="/clients/:clientId/portfolios/:portfolioId/stockrecords/:stockRecordId"
              element={<StockRecordDetails />}
            />

            <Route
              path="/clients/:clientId/dividends"
              element={<ClientDividendList />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
}
