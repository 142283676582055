import { useNavigate } from 'react-router-dom';
import { MenuConfig } from '../../../Components/ActionMenu';
import LinkAddButton from '../../../Components/LinkAddButton';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import useAPI from '../../../hooks/useAPI';

const columns = [
  {
    key: 'name',
    label: 'Nombre'
  },
  {
    key: 'rfc',
    label: 'RFC'
  },
  {
    key: 'portfoliosCount',
    label: 'Cantidad de Portafolios'
  }
];

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir',
      onClick: ({ row, navigate }) => {
        navigate(`/banking/account-statements/companies/${row.id}/portfolios`);
      }
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreatePortfolioButton() {
  return (
    <LinkAddButton to="/banking/account-statements/create-portfolio">
      Crear Portafolio
    </LinkAddButton>
  );
}

function CompaniesList() {
  const navigate = useNavigate();
  rowMenuConfig.onClick = (onClick, row) => onClick({ row, navigate });

  const { data, isLoading, isEmpty } = useAPI('/banking/companies');

  const handleRowClick = (evt, row) => {
    navigate(`/banking/account-statements/companies/${row.id}/portfolios`);
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Empresas con Portafolio"
        description="Aqui puedes ver las Empresas que traen al menos un Portafolio, tambien puedes crear portafolios nuevos."
      >
        {!isLoading && data.length > 0 && <CreatePortfolioButton />}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreatePortfolioButton />
        </div>
      )}

      {!isEmpty && data && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          hover
        />
      )}
    </div>
  );
}

export default CompaniesList;
