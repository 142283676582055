import { createContext } from 'react';

import Session from './Session';
import Clients from './Clients';
import Companies from './Companies';
import ReInvoice from './ReInvoice';
import Users from './Users';
import Modals from './Modals';
import ModalsStore from './ModalsStore';

const session = new Session();
const clients = new Clients();
const companies = new Companies();
const reInvoice = new ReInvoice();
const users = new Users();
const modals = new Modals();
const modalsStore = new ModalsStore();

export const stores = {
  session,
  clients,
  companies,
  reInvoice,
  users,
  modals,
  modalsStore
};

window.stores = stores;

const StoresContext = createContext(stores);

export default StoresContext;
