import { action, makeAutoObservable, runInAction } from 'mobx';

import HTTP from '../Services/HTTP';
import modals from '../utils/modals';

class Users {
  items = [];
  publicItems = [];
  isFetching = false;
  currentCompany = null;

  constructor() {
    makeAutoObservable(this);
  }

  setFetching = action((value) => {
    this.isFetching = value;
  });

  fetch = async () => {
    this.setFetching(true);
    runInAction(() => {
      this.items = [];
    });

    const response = await HTTP.get('/users');
    const data = await response.json();

    runInAction(() => {
      this.items = data;
    });
    this.setFetching(false);
  };

  fetchPublic = async () => {
    this.setFetching(true);
    runInAction(() => {
      this.publicItems = [];
    });

    const response = await HTTP.get('/users/public');
    const data = await response.json();

    runInAction(() => {
      this.publicItems = data;
    });
    this.setFetching(false);
  };

  create = async (values) => {
    const response = await HTTP.post(`/users`, values);
    const data = await response.json();

    return data;
  };

  patch = async (userId, values) => {
    const response = await HTTP.patch(`/users/${userId}`, values);
    const data = await response.json();

    return data;
  };

  delete = async (user) => {
    const response = await HTTP.delete(`/users/${user.id}`);
    const data = await response.json();

    return data?.success;
  };
}

export default Users;
