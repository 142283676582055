import { Routes, Route } from 'react-router-dom';

import UsersList from './Pages/UsersList';
import ManageUser from './Pages/ManageUser';

function UsersApp() {
  return (
    <main className="flex-1 h-screen">
      <Routes>
        <Route path="/" element={<UsersList />} />
        <Route path="/create" element={<ManageUser />} />
        <Route path="/edit/:userId" element={<ManageUser />} />
      </Routes>
    </main>
  );
}

export default UsersApp;
