import { Routes, Route } from 'react-router-dom';

import BatchList from './Pages/BatchList';
import CreateBatch from './Pages/CreateBatch';
import InspectBatch from './Pages/InspectBatch';
// import CreateCompany from './Pages/CreateCompany'
// import ManageCompany from './Pages/ManageCompany';

function ReInvoiceApp() {
  return (
    <main className="flex-1 h-screen">
      <Routes>
        <Route path="/" element={<BatchList />} />
        <Route path="/create" element={<CreateBatch />} />
        <Route path="/view/:batchId" element={<InspectBatch />} />
        {/* <Route path="/create" element={<CreateCompany />} />
        <Route path="/view/:companyId" element={<ManageCompany /> } /> */}
      </Routes>
    </main>
  );
}

export default ReInvoiceApp;
