import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import serialize from 'form-serialize';

import TablePageHeading from '../../../Components/TablePageHeading';
import LinkButton from '../../../Primitives/LinkButton';
import http from '../../../Services/HTTP';
import useAPI from '../../../hooks/useAPI';

function CreatePortfolio() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [CompanyId, setCompanyId] = useState(
    searchParams.get('forceCompanyId') || ''
  );

  const {
    data: companies,
    isEmpty,
    reload
  } = useAPI(`/smart/query?model=Company`);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = serialize(evt.target, { hash: true });
    await http.post('/banking/portfolios', data);
    // const portfolio = await response.json();
    navigate(
      `/banking/account-statements/companies/${data.CompanyId}/portfolios`
    );
    return false;
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/banking/account-statements"
        title="Crear Portafolio"
        description="Llena el siguiente formulario para agregar un portafolio a una empresa, empieza seleccionando la empresa a la que le quieres agregar un portafolio nuevo."
      />

      <form onSubmit={handleSubmit} className="space-y-6">
        <fieldset disabled={isEmpty}>
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Empresa y nombre de portafolio
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Selecciona la empresa a la que le quieres agregar un
                  portafolio nuevo y elija un buen nombre para el portafolio.
                </p>
              </div>

              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="CompanyId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Empresa
                    </label>
                    <select
                      name="CompanyId"
                      id="CompanyId"
                      onChange={(e) => setCompanyId(e.target.value)}
                      value={CompanyId}
                      className="mt-1 focus:ring-indigo-500 disabled:bg-gray-100 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      {companies && (
                        <>
                          <option value="">&hellip; selecciona</option>
                          {companies.map((company) => (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    <div className="text-xs text-gray-600 mt-1">
                      No esta la empresa que buscas?{' '}
                      <Link
                        to="/receiver-companies?create"
                        className="underline hover:text-gray-500"
                      >
                        Crea la ahora
                      </Link>{' '}
                      y regresa a{' '}
                      <button
                        type="button"
                        onClick={reload}
                        className="underline cursor-pointer hover:text-gray-500"
                      >
                        refrescar la lista
                      </button>
                      .
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nombre del portafolio nuevo
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="mt-1 focus:ring-indigo-500 disabled:bg-gray-100 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="initialBalance"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Saldo inicial
                    </label>
                    <input
                      type="text"
                      name="initialBalance"
                      id="initialBalance"
                      className="mt-1 focus:ring-indigo-500 disabled:bg-gray-100 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <LinkButton color="secondary" to="/banking/account-statements">
              Cancelar
            </LinkButton>

            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 disabled:bg-gray-200 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Enviar
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default observer(CreatePortfolio);
