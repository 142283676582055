import { Routes, Route } from 'react-router-dom';

import CompaniesListNew from './Pages/CompaniesList.new';

import CreateCompany from './Pages/CreateCompany';

function CompaniesApp() {
  return (
    <main className="flex-1 h-screen">
      <Routes>
        <Route path="/" element={<CompaniesListNew />} />
        <Route path="/create" element={<CreateCompany />} />
        <Route path="/:companyId/edit" element={<CreateCompany />} />
      </Routes>
    </main>
  );
}

export default CompaniesApp;
