import { Link } from 'react-router-dom';

const secondaryClassNames =
  'relative inline-flex items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500';
const primaryClassNames =
  'relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';

function LinkButton({ to, children, color }) {
  return (
    <Link
      to={to}
      type="button"
      className={
        color === 'secondary' ? secondaryClassNames : primaryClassNames
      }
    >
      {children}
    </Link>
  );
}

export default LinkButton;
