import { Routes, Route } from 'react-router-dom';

import CompaniesList from './Pages/CompaniesList';
import CreatePortfolio from './Pages/CreatePortfolio';
import PortfoliosList from './Pages/PortfoliosList';
import MovementList from './Pages/MovementList';

function CompaniesApp() {
  return (
    <main className="flex-1">
      <Routes>
        <Route path="/account-statements" element={<CompaniesList />} />
        <Route
          path="/account-statements/create-portfolio"
          element={<CreatePortfolio />}
        />
        <Route
          path="/account-statements/companies/:companyId/portfolios"
          element={<PortfoliosList />}
        />
        <Route
          path="/account-statements/companies/:companyId/portfolios/:portfolioId"
          element={<MovementList />}
        />
      </Routes>
    </main>
  );
}

export default CompaniesApp;
