import { runInAction } from 'mobx';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuConfig } from '../../../Components/ActionMenu';
import DateTime from '../../../Components/DateTime';

import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import useAPI from '../../../hooks/useAPI';
import { getFormData } from '../../../Modals';
import Button from '../../../Primitives/Button';
import http from '../../../Services/HTTP';
import modals from '../../../utils/modals';

function CreateCompanyButton({ onClick }) {
  return <Button onClick={onClick}>Agregar Empresa</Button>;
}

const columns = [
  {
    key: 'createdAt',
    label: 'Fecha creación',
    sortable: true,
    template: (cell) => <DateTime>{cell}</DateTime>
  },
  {
    key: 'name',
    sortable: true,
    label: 'Nomnbre'
  },
  {
    key: 'rfc',
    sortable: true,
    label: 'RFC'
  },
  {
    key: 'zipCode',
    sortable: true,
    label: 'Codigo Postal'
  },
  {
    key: 'updatedAt',
    label: 'Fecha actualización',
    sortable: true,
    template: (cell) => <DateTime>{cell}</DateTime>
  }
];

const receiverCompanyForm = [
  {
    key: 'rfc',
    label: 'RFC',
    type: 'text'
  },
  {
    key: 'password1',
    label: 'Contraseña SAT',
    type: 'text'
  },
  {
    key: 'name',
    label: 'Razón social',
    type: 'text'
  },
  {
    key: 'zipCode',
    label: 'Codigo Postal',
    type: 'text'
  }
];

const promptCompanyEdit = async (row, mutate) => {
  const { canceled, data: editCompanyData } = await getFormData(
    'Editar Empresa receptor',
    'El codigo postal y la razon social se actualizan automaticamente cada semana.',
    receiverCompanyForm,
    row
  );

  if (!canceled) {
    await http.patch(`/smart/${row.id}?model=Company`, editCompanyData);
    mutate();
  }
};

const menuConfig = new MenuConfig([
  {
    key: 'edit',
    label: 'Editar',
    onClick: ({ row, mutate }) => promptCompanyEdit(row, mutate)
  },
  {
    key: 'refresh',
    label: 'Refrescar en SAT',
    onClick: async ({ row }) => {
      const response = await http.get(
        `/reinvoice/update-receiver-company/${row.id}`
      );

      const result = await response.json();

      runInAction(() => {
        if (result.name) {
          row.name = result.name;
        }
        if (result.zipCode) {
          row.zipCode = result.zipCode;
        }
      });
    }
  },
  {
    key: 'delete',
    label: 'Borrar',
    onClick: async ({ row, mutate }) => {
      const confirmed = await modals.confirm(
        'Confirma que deseas borrar esta Empresa',
        'Una vez confirmando, ya no puedes usarla.'
      );
      if (!confirmed) {
        return;
      }

      const response = await http.delete(`/smart?model=Company&id=${row.id}`);
      const data = await response.json();

      if (data?.success === true) {
        mutate();
      }
    }
  }
]);

function CompaniesList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading, isEmpty, mutate } = useAPI(
    '/smart/query?model=Company'
  );

  menuConfig.onClick = (onClick, row) => onClick({ row, mutate, navigate });

  const showTable = !isLoading && !isEmpty;

  const promptCompanyCreation = async () => {
    const { canceled, data: createCompanyData } = await getFormData(
      'Crear Empresa receptor',
      'El codigo postal y la razon social se actualizan automaticamente cada semana.',
      receiverCompanyForm
    );

    if (!canceled && createCompanyData) {
      await http.post(`/smart/create?model=Company`, createCompanyData);
      mutate();
      if (location.search?.includes('create')) {
        navigate('/banking/account-statements/create-portfolio');
      }
    }
  };

  useEffect(() => {
    if (location.search?.includes('create')) {
      promptCompanyCreation();
    }
  }, []);

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Empresas receptor"
        description="Un listado de empresas receptor para usar en el modulo de refacturación."
      >
        {!isLoading && data.length > 0 && (
          <CreateCompanyButton onClick={promptCompanyCreation} />
        )}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateCompanyButton onClick={promptCompanyCreation} />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={menuConfig}
          rowKey="id"
          striped
        />
      )}
    </div>
  );
}

export default CompaniesList;
