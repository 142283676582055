import serialize from 'form-serialize';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StoresContext from '../../../Stores';

function CreateClient() {
  const { clients } = useContext(StoresContext);

  const navigate = useNavigate();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = serialize(evt.currentTarget, { hash: true });
    const client = await clients.create({ name: data.name });
    navigate(`/stocks/clients/${client.id}/portfolios`);
    return false;
  };

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <h1 className="text-2xl font-semibold text-gray-900">Crear Cliente</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="flex flex-col">
            <form
              className="space-y-8 divide-y divide-gray-200"
              onSubmit={handleSubmit}
            >
              <div className="space-y-8 divide-y divide-gray-200">
                <div>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    La informacion que usted propone aqui, se usara para crear
                    un nuevo cliente en el sistema.
                  </p>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="mt-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Enviar
                  </button>
                  <Link
                    to="/stocks/clients"
                    className="ml-4 text-indigo-600 text-sm hover:text-indigo-900"
                  >
                    cancelar
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
