/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-undef */
import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { KeyIcon, ServerIcon } from '@heroicons/react/outline';
import { useLocalObservable, observer } from 'mobx-react-lite';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import PrimaryButton from '../Primitives/PrimaryButton';
import Button from '../Primitives/Button';
import getFormData from '../utils/getFormData';

function Input({ field, defaultValue }) {
  const { key, label, type, placeholder = '', info, readOnly } = field;
  return (
    <div className="mt-4">
      <label htmlFor={key} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        {type === 'text' && (
          <input
            type={type}
            name={key}
            readOnly={readOnly}
            id={key}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
        )}
        {type === 'textarea' && (
          <textarea
            readOnly
            className="w-full rounded shadow-sm border-gray-400 font-mono text-xs"
            rows={32}
          >
            {defaultValue}
          </textarea>
        )}
        {info && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <QuestionMarkCircleIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    </div>
  );
}

function ValidatePasswordModal({
  title,
  description,
  fields,
  resolve,
  values,
  reject
}) {
  const state = useLocalObservable(() => ({
    isOpen: false,

    close() {
      state.isOpen = false;
    },
    open() {
      state.isOpen = true;
    }
  }));

  const handleDismiss = () => {
    state.close();
    resolve({ canceled: true });
  };

  useEffect(() => {
    state.open();
  }, []);

  const handleSubmit = (evt) => {
    const data = getFormData(evt);

    resolve({ canceled: false, data });
    state.close();

    return false;
  };

  return (
    <Transition.Root show={state.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleDismiss}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={handleSubmit}
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <KeyIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                    <div className="mt-2">
                      {fields.map((field) => (
                        <Input
                          key={field.key}
                          field={field}
                          defaultValue={values?.[field.key]}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <PrimaryButton
                  type="submit"
                  className="ml-3"
                  onClick={handleDismiss}
                >
                  Close
                </PrimaryButton>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default observer(ValidatePasswordModal);
