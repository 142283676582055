/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { KeyIcon, ServerIcon } from '@heroicons/react/outline';
import { useLocalObservable, observer, Observer } from 'mobx-react-lite';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import PrimaryButton from '../Primitives/PrimaryButton';
import Button from '../Primitives/Button';
import getFormData from '../utils/getFormData';
import UTCDatePicker from '../Components/UTCDatePicker';

function Input({ field, defaultValue, additional }) {
  const {
    key,
    label,
    type,
    placeholder = '',
    info,
    description,
    required,
    options,
    rows,
    readOnly,
    customProps
  } = field;

  const [date, setDate] = useState(new Date(defaultValue || null));

  return (
    <div className="mt-4">
      <label htmlFor={key} className="block text-sm font-medium text-gray-700">
        {label}
      </label>

      {type === 'date' && (
        <UTCDatePicker
          dateFormat="yyyy-MM-dd"
          name={key}
          onChange={setDate}
          selected={date}
          className="mt-1 w-full block pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        />
      )}

      {type === 'text' && (
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name={key}
            required={required}
            readOnly={readOnly}
            id={key}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            defaultValue={defaultValue}
            placeholder={placeholder}
          />

          {info && (
            <div
              className="absolute inset-y-0 z-10 right-0 pr-3 flex items-center pointer-pointer"
              title={info}
            >
              <QuestionMarkCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      )}
      {type === 'number' && (
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="number"
            name={key}
            readOnly={readOnly}
            id={key}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            defaultValue={defaultValue}
            placeholder={placeholder}
            {...customProps}
          />

          {info && (
            <div
              className="absolute inset-y-0 z-10 right-0 pr-3 flex items-center pointer-pointer"
              title={info}
            >
              <QuestionMarkCircleIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      )}
      {type === 'textarea' && (
        <textarea
          readOnly={readOnly}
          name={key}
          className="w-full rounded-md mt-1 border-gray-300 font-mono text-xs"
          rows={rows}
        >
          {defaultValue}
        </textarea>
      )}
      {type === 'checkbox' &&
        options.map((option) => (
          <React.Fragment key={`checkbox-${key}-${option}`}>
            <input
              id={`checkbox-${key}-${option}`}
              name={`${key}`}
              type="checkbox"
            />
            <label
              htmlFor={`checkbox-${key}-${option}`}
              className="ml-2 text-sm"
            >
              {option}
            </label>
          </React.Fragment>
        ))}

      {type === 'radio' &&
        options.map((option) => (
          <React.Fragment key={`radio-${key}-${option.value}`}>
            <input
              id={`radio-${key}-${option.value}`}
              name={`${key}`}
              value={option.value}
              type="radio"
              defaultChecked={option.value === defaultValue}
            />
            <label
              htmlFor={`radio-${key}-${option.value}`}
              className="ml-1 text-sm mr-4"
            >
              {option.label}
            </label>
          </React.Fragment>
        ))}

      {description && (
        <div className="text-gray-500 text-xs mt-2">{description}</div>
      )}
    </div>
  );
}

function ValidatePasswordModal({
  title,
  description,
  fields,
  resolve,
  values,
  reject
}) {
  const [isOpen, setOpen] = useState(false);

  const handleDismiss = () => {
    setOpen(false);
    resolve({ canceled: true });
  };

  const handleSubmit = (evt) => {
    const data = getFormData(evt);

    resolve({ canceled: false, data });
    setOpen(false);

    return false;
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleDismiss}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={handleSubmit}
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ServerIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                    <div className="mt-2">
                      <Observer>
                        {() =>
                          fields.map((field) => (
                            <Input
                              key={field.key}
                              field={field}
                              defaultValue={values?.[field.key]}
                              additional={{ fields }}
                            />
                          ))
                        }
                      </Observer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span className="ml-3">
                  <Button type="submit">Enviar</Button>
                </span>
                <Button color="secondary" onClick={handleDismiss}>
                  Cerrar
                </Button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ValidatePasswordModal;
