import serialize from 'form-serialize';
import { DocumentAddIcon } from '@heroicons/react/outline';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import { Link, useNavigate } from 'react-router-dom';
import { runInAction } from 'mobx';
import StoresContext from '../../../Stores';
import http from '../../../Services/HTTP';
import UTCDatePicker from '../../../Components/UTCDatePicker';

const preventDragOverDefault = (evt) => {
  evt.stopPropagation();
  evt.preventDefault();
};

function currencyFormat(num) {
  return `$${num
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

function DropFiles() {
  const { reInvoice } = useContext(StoresContext);

  const handleDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (evt.dataTransfer.files && evt.dataTransfer.files.length > 0) {
      reInvoice.setFiles(evt.dataTransfer.files);
      evt.dataTransfer.clearData();
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={preventDragOverDefault}
      className="grid flex justify-center flex-col border-2 border-gray-300 border-dashed rounded-lg p-12 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <DocumentAddIcon className="w-16 text-gray-400 justify-self-center" />
      <div className="mt-2 block text-sm font-medium text-gray-900">
        Soltar archivos XML aqui
      </div>
    </div>
  );
}

const ExampleCustomTimeInput = observer(({ value, onChange }) => {
  const { reInvoice } = useContext(StoresContext);

  return (
    <input
      value={reInvoice.time}
      onChange={(e) => reInvoice.setTime(e.target.value)}
    />
  );
});

const CreateBatch = observer(() => {
  const { reInvoice, companies } = useContext(StoresContext);

  const state = useLocalObservable(() => ({
    credits: 0,
    clickedCreate: false,
    hasCompanySelected: false,
    createClicked: () => {
      state.clickedCreate = true;
    },
    resetCreateClicked: () => {
      state.clickedCreate = false;
    },
    getCredits: async (evt) => {
      const transmitterCompanyId = evt.target.value;

      reInvoice.checkStamped(transmitterCompanyId);

      if (!transmitterCompanyId || transmitterCompanyId === '') {
        runInAction(() => {
          state.credits = 0;
          state.hasCompanySelected = false;
        });
        return;
      }

      const response = await http.get(
        `/companies/${transmitterCompanyId}/credits`
      );

      const { DetallesPaqueteCreditos } = await response.json();

      if (DetallesPaqueteCreditos) {
        // array
        if (DetallesPaqueteCreditos?.length) {
          const activePackage = DetallesPaqueteCreditos.find(
            (pack) => pack.EnUso === 'true'
          );
          runInAction(() => {
            state.hasCompanySelected = evt.target.value;
            state.credits = Number(activePackage.TimbresRestantes);
          });
        } else if (DetallesPaqueteCreditos) {
          runInAction(() => {
            state.hasCompanySelected = evt.target.value;
            state.credits = Number(DetallesPaqueteCreditos.TimbresRestantes);
          });
        }
      }
    }
  }));

  const navigate = useNavigate();

  useEffect(() => {
    companies.fetch();
    reInvoice.setCurrentTime();
  }, [companies]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!state.clickedCreate) {
      state.createClicked();

      const data = serialize(evt.currentTarget, { hash: true });

      data.date = reInvoice.date.toISOString().split('T')[0];
      data.time = reInvoice.time;

      const newBatch = await reInvoice.createBatch(data);

      if (newBatch) {
        await reInvoice.uploadInvoices(newBatch, navigate);
        state.resetCreateClicked();
      }
    }

    return false;
  };

  if (reInvoice.isUploadModeActive) {
    return (
      <div className="flex grid justify-center h-screen">
        <div className="self-center">
          subiendo facturas {reInvoice.uploadedFiles}/
          {reInvoice.currentBatch.files.length}
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="bg-white max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pb-8">
        <div className="">
          <Link
            to="/reinvoice"
            className="text-indigo-600 text-sm hover:text-indigo-900"
          >
            &laquo; regresar
          </Link>
        </div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap mt-2">
          <h1 className="text-xl font-semibold text-gray-900">Crear Batch</h1>
        </div>

        <div>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Aqui usted puede soltar la coleccion de facutras XML para estimar
            los totales, antes de timbrar, timbrar el batch sucedera en un paso
            mas adelante, manualmente.
          </p>
          <div className="py-4">
            <DropFiles />
          </div>
          <div className="py-4 text-gray-700">
            {reInvoice.currentBatch && (
              <>
                {reInvoice.currentBatch?.files.length} facturas cargadas con
                TipoDeComprobante{' '}
                <span className="font-bold font-mono text-sm">
                  &quot;I&quot;
                </span>
              </>
            )}
            {reInvoice.otherReceiptTypes.length > 0 && (
              <ul>
                {reInvoice.otherReceiptTypes.map((otherReceiptType) => (
                  <li key={`type-${otherReceiptType.label}`}>
                    {otherReceiptType.count} documentos{' '}
                    <span className="font-semibold">no cargados</span> con
                    TipoDeComprobante{' '}
                    <span className="font-bold font-mono text-sm">
                      &quot;{otherReceiptType.label}&quot;
                    </span>
                  </li>
                ))}
              </ul>
            )}

            {reInvoice.stampedInvoices.length > 0 && (
              <div className="mt-4">
                <span className="font-medium text-red-400">
                  Los siguientes archivos ya fueron timbrados anteriormente,
                  favor de excluirlos al subir/soltar, para poder timbrar.
                </span>
                <ul>
                  {reInvoice.stampedInvoices.map((stampedInvoice) => (
                    <li key={stampedInvoice.sheet}>
                      {stampedInvoice.fileName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">
                Calculo de totales
              </span>
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="adjustPercentage"
                className="block text-sm font-medium text-gray-700"
              >
                Ajuste al importe en %
              </label>
              <div className="mt-1 flex rounded-md">
                <input
                  type="text"
                  name="adjustPercentage"
                  id="adjustPercentage"
                  onChange={reInvoice.setDiscount}
                  value={reInvoice.discount}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
          </div>
          {reInvoice.currentBatch && (
            <div className="grid grid-cols-2 content-start py-4">
              <div>
                <div>
                  antes de aplicar el descuento:
                  <br />
                  <strong>Sub total:</strong>{' '}
                  {currencyFormat(reInvoice.currentBatch.before.subTotal)}
                </div>
                <div>
                  <strong>Total:</strong>{' '}
                  {currencyFormat(reInvoice.currentBatch.before.total)}
                </div>
              </div>

              <div>
                <div>
                  despues de aplicar el descuento:
                  <br />
                  <strong>Sub total:</strong>{' '}
                  {currencyFormat(reInvoice.subTotalAfter)}
                </div>
                <div>
                  <strong>Total:</strong> {currencyFormat(reInvoice.totalAfter)}
                </div>
              </div>
            </div>
          )}

          {reInvoice.currentBatch?.files?.length > 0 && (
            <>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="px-2 bg-white text-sm text-gray-500">
                    Datos generales
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-2 pt-4 gap-x-4">
                <div>
                  <label
                    htmlFor="transmitterCompany"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Emisor nuevo
                  </label>
                  <select
                    id="transmitterCompanyId"
                    name="transmitterCompanyId"
                    className="mt-1 block pl-3 w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue=""
                    onChange={state.getCredits}
                  >
                    <option value="">&hellip;</option>
                    {companies.items.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                  {state.hasCompanySelected && (
                    <>{state.credits} timbres restantes</>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="transmitterCompany"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fecha y hora de facturacion
                  </label>
                  <div className="relative">
                    <UTCDatePicker
                      selected={reInvoice.date}
                      onChange={reInvoice.setDate}
                      dateFormat="yyyy-MM-dd"
                      showTimeInput
                      className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      maxDate={new Date()}
                      customTimeInput={<ExampleCustomTimeInput />}
                    />
                    <div
                      className="absolute text-sm text-gray-500"
                      style={{ top: 13, left: 175 }}
                    >
                      {reInvoice.time}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="paymentForm"
                    className="block text-sm font-medium text-gray-700"
                  >
                    FormaPago
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      type="text"
                      name="paymentForm"
                      id="paymentForm"
                      defaultValue={99}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="paymentMethod"
                    className="block text-sm font-medium text-gray-700"
                  >
                    MetodoPago
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      type="text"
                      name="paymentMethod"
                      id="paymentMethod"
                      defaultValue="PPD"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block rounded-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
              <button
                disabled={
                  reInvoice.fetchingExistingStamps ||
                  reInvoice.stampedInvoices.length > 0 ||
                  state.clickedCreate
                }
                type="submit"
                className="mt-6 relative inline-flex disabled:bg-gray-300 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Crear Batch
              </button>{' '}
              {'<-'} Esto no timbra, solo se creara el batch.
            </>
          )}
        </div>
      </div>
    </form>
  );
});

export default CreateBatch;
