import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { observer } from 'mobx-react-lite';
import ReactDatePicker from 'react-datepicker';
import classNames from '../../utils/classNames';

const filterToMex = {
  rangeEndDate: 'Fin periodo',
  rangeStartDate: 'Inicio periodo'
};

const fullDate = (date) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

function FilterBar({ tableState }) {
  return (
    <span className="text-gray-600 font-medium hover:underline cursor-pointer hover:text-gray-700">
      <div className="hidden print:block">
        {tableState.filters.map(
          (filter) =>
            (filter.rangeStartDate || filter.rangeEndDate) && (
              <>
                {filter.label},{' '}
                {filter.rangeStartDate &&
                  `${filterToMex.rangeStartDate} ${fullDate(
                    filter.rangeStartDate
                  )}`}
                {filter.rangeEndDate &&
                  ` ${filterToMex.rangeEndDate} ${fullDate(
                    filter.rangeEndDate
                  )}`}
              </>
            )
        )}
      </div>
      {tableState.filters.length > 0 && (
        <Popover
          as="div"
          className="print:hidden relative inline-block text-left"
        >
          <div>
            <Popover.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Filtros
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="origin-top-right absolute right-0 z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {tableState.filters.map((filter) => (
                  <span
                    key={filter.key}
                    className={classNames(
                      'text-gray-700 block px-4 py-2 text-sm'
                    )}
                  >
                    <div>{filter.label}</div>
                    {filter.type === 'date' && (
                      <>
                        <ReactDatePicker
                          dateFormat="yyyy-MM-dd"
                          onChange={tableState.setFilter(
                            filter,
                            'rangeStartDate'
                          )}
                          selected={filter.rangeStartDate}
                          maxDate={filter.rangeEndDate}
                          placeholderText={filterToMex.rangeStartDate}
                          className="mt-1 w-full block pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />
                        <ReactDatePicker
                          dateFormat="yyyy-MM-dd"
                          onChange={tableState.setFilter(
                            filter,
                            'rangeEndDate'
                          )}
                          selected={filter.rangeEndDate}
                          minDate={filter.rangeStartDate}
                          placeholderText={filterToMex.rangeEndDate}
                          className="mt-1 w-full block pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />

                        <button
                          type="button"
                          className="text-gray-500 text-xs"
                          onClick={tableState.resetFilters(filter, [
                            'rangeStartDate',
                            'rangeEndDate'
                          ])}
                        >
                          quitar filtro
                        </button>
                      </>
                    )}
                  </span>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </span>
  );
}

export default observer(FilterBar);
