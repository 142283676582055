import { Link, useNavigate, useParams } from 'react-router-dom';

import { useContext } from 'react';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import StoresContext from '../../../Stores';
import NumberFormat from '../../../Primitives/NumberFormat';

const columns = [
  {
    key: 'name',
    label: 'Nombre',
    sortable: true
  },
  {
    key: 'symbol',
    label: 'Simbolo',
    sortable: true
  },
  {
    key: 'quantitySum',
    label: 'Numero de Acciones',
    type: 'number',
    footerSum: true,
    sortable: true,
    template: (cell) => <NumberFormat>{cell}</NumberFormat>
  },
  {
    key: 'avgUnitPrice',
    label: 'Costo promedio por accion',
    type: 'number',
    sortable: true,
    template: (cell) => <NumberFormat>{cell}</NumberFormat>
  },
  {
    key: 'totalCostSum',
    label: 'Costo total',
    footerSum: true,
    type: 'number',
    sortable: true,
    template: (cell) => <NumberFormat>{cell}</NumberFormat>
  }
];

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir detalle',
      onClick: ({ row, navigate, clientId, portfolioId }) =>
        navigate(
          `/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords/${row.id}`
        )
    },
    {
      key: 'registro',
      label: 'Agregar registro',
      onClick: ({ row, navigate, clientId, portfolioId }) =>
        navigate(
          `/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords/create?forceName=${row.name}&forceSymbol=${row.symbol}`
        )
    },
    {
      key: 'dividends',
      label: ({ row }) => `Dividendos ${row.name}`,
      onClick: ({ row, navigate, clientId, portfolioId }) =>
        navigate(
          `/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords/${row.id}/dividends`
        )
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreateStockRecordButton({ clientId, portfolioId }) {
  return (
    <LinkAddButton
      to={`/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords/create`}
    >
      Agregar Registro
    </LinkAddButton>
  );
}

function StockRecords() {
  const navigate = useNavigate();
  const { clientId, portfolioId } = useParams();

  const { clients } = useContext(StoresContext);
  const { data, isLoading } = useAPI(
    `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords`
  );

  const isEmpty = !isLoading && data.portfolio.length === 0;

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, clients, navigate, clientId, portfolioId });

  const showTable = !isLoading && !isEmpty;

  const handleRowClick = (evt, row) => {
    navigate(
      `/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords/${row.id}`
    );
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl={`/stocks/clients/${clientId}/portfolios`}
        title="Acciones"
        description={
          isLoading
            ? 'cargando'
            : `Estas viendo el portafolio ${data.portfolioName} del cliente ${data.clientName}`
        }
      >
        {!isLoading && !isEmpty && (
          <div>
            <Link
              to="./../dividends"
              className="text-indigo-600 font-medium mr-4 relative bottom-1 hover:underline cursor-pointer hover:text-indigo-700"
            >
              Dividendos
            </Link>
            <CreateStockRecordButton
              clientId={clientId}
              portfolioId={portfolioId}
            />
          </div>
        )}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateStockRecordButton
            clientId={clientId}
            portfolioId={portfolioId}
          />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data.portfolio}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          hover
        />
      )}
    </div>
  );
}

export default StockRecords;
