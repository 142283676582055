import { useNavigate, useParams } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import DateTime from '../../../Components/DateTime';
import http from '../../../Services/HTTP';
import importAccountStatement from '../helpers/importAccountStatement';
import { confirm, getFormData } from '../../../Modals';

const first = (arr) => {
  if (arr && arr.length > 0) {
    return arr[0];
  }
  return null;
};

const columns = [
  {
    key: 'name',
    label: 'Nombre',
    sortable: true
  },
  {
    key: 'balanceAmount',
    label: 'Saldo',
    type: 'number',
    sortable: true,
    sortValue: (cell, row) => row.balanceAmount || 0,
    template: (cell, row) => (
      <CurrencyFormat
        value={row.balanceAmount || 0}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        suffix=" MXN"
        displayType="text"
      />
    )
  },
  {
    key: 'createdAt',
    label: 'Fecha creación',
    sortable: true,
    type: 'date',
    template: (cell) => <DateTime>{cell}</DateTime>
  }
];

const portfolioForm = [
  {
    key: 'name',
    type: 'text',
    label: 'Nombre del portafolio'
  },
  {
    key: 'initialBalance',
    type: 'number',
    label: 'Saldo inicial'
  }
];

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir',
      onClick: ({ row, params, navigate }) => {
        navigate(
          `/banking/account-statements/companies/${params.companyId}/portfolios/${row.id}`
        );
      }
    },
    {
      key: 'edit',
      label: 'Editar',
      onClick: async ({ row, mutate }) => {
        const { canceled, data } = await getFormData(
          'Editar portafolio',
          'Revisa y cambia los datos de este portafolio',
          portfolioForm,
          row
        );

        if (!canceled && data) {
          await http.patch(`/smart/${row.id}/?model=BankingPortfolio`, data);
          mutate();
        }
      }
    },
    {
      key: 'delete',
      label: 'Borrar',
      validator: ({ isAdmin }) => isAdmin === true,
      onClick: async ({ row, mutate }) => {
        const confirmed = await confirm(
          'Confirma que deseas borrar este Portafolio'
        );
        if (!confirmed) {
          return;
        }

        const response = await http.delete(
          `/smart?model=BankingPortfolio&id=${row.id}`
        );
        const data = await response.json();

        if (data?.success === true) {
          mutate();
        }
      }
    }
    // {
    //   key: 'import',
    //   label: 'Importar estado de cuenta',
    //   onClick: ({ row, params, mutate }) => {
    //     importAccountStatement({
    //       companyId: params.companyId,
    //       portfolioId: row.id,
    //       mutate
    //     });
    //   }
    // }
  ],
  {
    validator: (validator, row, isAdmin) => validator({ row, isAdmin })
  }
);

function CreatePortfolioButton({ companyId }) {
  return (
    <LinkAddButton
      to={`/banking/account-statements/create-portfolio?forceCompanyId=${companyId}`}
    >
      Crear Portafolio
    </LinkAddButton>
  );
}

function PortfoliosList() {
  const navigate = useNavigate();
  const params = useParams();

  const { data, isLoading, isEmpty, mutate } = useAPI(
    `/banking/companies/${params.companyId}/portfolios`
  );

  const { data: companyData } = useAPI(
    `/smart/query?model=Company&where={"id":"${params.companyId}"}`
  );

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, navigate, params, mutate });

  const handleRowClick = (evt, row) => {
    navigate(
      `/banking/account-statements/companies/${params.companyId}/portfolios/${row.id}`
    );
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="./../../.."
        title="Portafolios"
        description="Listado de Portafolios de la empresa seleccionada"
      >
        {!isLoading && data.length > 0 && (
          <CreatePortfolioButton companyId={params.companyId} />
        )}
      </TablePageHeading>

      <div className="flex space-x-4 mb-4">
        <h1 className="text-2xl text-gray-500">
          {companyData && companyData[0].name}
        </h1>
      </div>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreatePortfolioButton companyId={params.companyId} />
        </div>
      )}

      {!isEmpty && data && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          hover
        />
      )}
    </div>
  );
}

export default PortfoliosList;
