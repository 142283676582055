import { useNavigate } from 'react-router-dom';
import { MenuConfig } from '../../../Components/ActionMenu';
import LinkAddButton from '../../../Components/LinkAddButton';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import useAPI from '../../../hooks/useAPI';
import http from '../../../Services/HTTP';
import modals from '../../../utils/modals';

function CreateCompanyButton() {
  return <LinkAddButton to="./create">Agregar Empresa</LinkAddButton>;
}

const columns = [
  {
    key: 'name',
    label: 'Nomnbre'
  },
  {
    key: 'rfc',
    label: 'RFC'
  }
];

const menuConfig = new MenuConfig([
  {
    key: 'edit',
    label: 'Editar',
    onClick: ({ row, navigate }) => {
      navigate(`/companies/${row.id}/edit`);
    }
  },
  {
    key: 'delete',
    label: 'Borrar',
    onClick: async ({ row, mutate }) => {
      const confirmed = await modals.confirm(
        'Confirma que deseas borrar esta Empresa',
        'Una vez confirmando, ya no puedes usarla.'
      );
      if (!confirmed) {
        return;
      }

      const response = await http.delete(`/companies/${row.id}`);
      const data = await response.json();

      if (data?.success === true) {
        mutate();
      }
    }
  }
]);

function CompaniesList() {
  const navigate = useNavigate();
  const { data, isLoading, isEmpty, mutate } = useAPI('/companies');

  menuConfig.onClick = (onClick, row) => onClick({ row, mutate, navigate });

  const showTable = !isLoading && !isEmpty;

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Empresas emisoras"
        description="Un listado de empresas para usar en el modulo de refacturación."
      >
        {!isLoading && data.length > 0 && <CreateCompanyButton />}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateCompanyButton />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={menuConfig}
          // onRowClick={handleRowClick}
          rowKey="id"
          striped
          hover
        />
      )}
    </div>
  );
}

export default CompaniesList;
