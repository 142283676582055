import React, { useContext } from 'react';
import serialize from 'form-serialize';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import UTCDatePicker from '../../../Components/UTCDatePicker';
import StoresContext from '../../../Stores';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CreateStockRecord() {
  const { clients } = useContext(StoresContext);

  const localState = useLocalObservable(() => ({
    recordDate: new Date(),
    onChangeRecordDate: (date) => {
      localState.recordDate = date;
    }
  }));

  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = serialize(evt.currentTarget, { hash: true, disabled: true });
    const response = await clients.createStockRecord(
      params.clientId,
      params.portfolioId,
      data
    );
    navigate(
      `/stocks/clients/${params.clientId}/portfolios/${params.portfolioId}/stockrecords/${response.id}`
    );
    return false;
  };

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <h1 className="text-2xl font-semibold text-gray-900">
          Crear Registro de una Accion
        </h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="flex flex-col">
            <form
              className="space-y-8 divide-y divide-gray-200"
              onSubmit={handleSubmit}
            >
              <div className="space-y-8 divide-y divide-gray-200">
                <div>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Crea un nuevo registro de una accion
                  </p>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={query.get('forceName')}
                          disabled={!!query.get('forceName')}
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Simbolo
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="symbol"
                          id="symbol"
                          value={query.get('forceSymbol')}
                          disabled={!!query.get('forceSymbol')}
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fecha original de registro
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Observer>
                          {() => (
                            <UTCDatePicker
                              dateFormat="dd-MM-yyyy"
                              name="recordDate"
                              onChange={localState.onChangeRecordDate}
                              selected={localState.recordDate}
                              className="mt-1 w-full block pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Observer>
                      </div>
                    </div>
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tipo
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <select
                          id="type"
                          disabled={!!query.get('forceType')}
                          value={query.get('forceType')}
                          name="type"
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        >
                          <option value="buy">compra</option>
                          <option value="sell">venta</option>
                          <option value="transfer+">traspaso +</option>
                          <option value="transfer-">traspaso -</option>
                          <option value="donation+">donación +</option>
                          <option value="donation-">donación -</option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="quantity"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cantidad
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="number"
                          name="quantity"
                          id="quantity"
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="unitPrice"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Precio unitario
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="unitPrice"
                          id="unitPrice"
                          className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="mt-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Enviar
                  </button>
                  <Link
                    to={`/stocks/clients/${params.clientId}/portfolios/${params.portfolioId}/stockrecords`}
                    className="ml-4 text-indigo-600 text-sm hover:text-indigo-900"
                  >
                    cancelar
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateStockRecord;
