import { useNavigate, useParams } from 'react-router-dom';

import { useContext } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import modals from '../../../utils/modals';
import StoresContext from '../../../Stores';
import Button from '../../../Primitives/Button';
import { getFormData } from '../../../Modals';
import http from '../../../Services/HTTP';

const columns = [
  {
    key: 'name',
    label: 'Nombre',
    sortable: true
  },
  {
    key: 'number',
    label: 'Numero',
    sortable: true
  },
  {
    key: 'bank',
    label: 'Banco',
    sortable: true
  },
  {
    key: '',
    label: 'Numero de Acciones',
    sortable: true,
    template: () => <>TBD</>
  }
];

const deleteAction = async ({ row, clients, clientId, mutate }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Portafolio',
    'Estas seguro que deseas borrar este Portafolio?'
  );
  if (confirmed) {
    await clients.deletePortfolio(clientId, row);
    mutate();
  }
};

const portfolioForm = [
  {
    key: 'name',
    label: 'Nombre',
    type: 'text'
  },
  {
    key: 'number',
    label: 'Numero',
    type: 'text'
  },
  {
    key: 'bank',
    label: 'Banco',
    type: 'text'
  }
];

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir',
      onClick: ({ row, navigate, clientId }) =>
        navigate(
          `/stocks/clients/${clientId}/portfolios/${row.id}/stockrecords`
        )
    },
    {
      key: 'edit',
      label: 'Editar',
      onClick: async ({ row, clientId, mutate }) => {
        const { canceled, data } = await getFormData(
          'Editar Portafolio',
          'Modifica los datos del portafolio como desea y confirme los cambios haciendo click en el boton de Enviar.',
          portfolioForm,
          row
        );

        if (!canceled && data) {
          await http.patch(`/stocks/${clientId}/portfolios/${row.id}`, data);
          mutate();
        }
      }
    },
    {
      key: 'dividends',
      label: ({ row }) => `Dividendos ${row.name}`,
      onClick: ({ row, navigate, clientId }) =>
        navigate(`/stocks/clients/${clientId}/portfolios/${row.id}/dividends`)
    },
    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreatePortfolioButton({ clientId, mutate, clientName }) {
  const handleClick = async () => {
    const { canceled, data } = await getFormData(
      'Portafolio Nuevo',
      `Ingrese los datos para agregar un portafolio nuevo al cliente ${clientName}.`,
      portfolioForm
    );

    if (!canceled && data) {
      await http.post(`/stocks/${clientId}/portfolios`, data);
      mutate();
    }
  };

  return (
    <Button onClick={handleClick}>
      <PlusIcon className="h-5 w-5 mr-2" /> Agregar Portafolio
    </Button>
  );
}

function Portfolios() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { clients } = useContext(StoresContext);
  const { data, isLoading, mutate } = useAPI(`/stocks/${clientId}`);

  const isEmpty = data?.portfolios?.length === 0;

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, clients, navigate, clientId, mutate });

  const showTable = !isLoading && !isEmpty;

  const handleRowClick = (evt, row) => {
    navigate(`/stocks/clients/${clientId}/portfolios/${row.id}/stockrecords`);
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/stocks/clients"
        title="Portafolios"
        description={
          isLoading ? (
            'cargando'
          ) : (
            <>
              Estas viendo todos los portafolios del cliente{' '}
              <span className="font-semibold">{data.name}</span>
            </>
          )
        }
      >
        {!isLoading && !isEmpty && (
          <CreatePortfolioButton
            clientId={clientId}
            mutate={mutate}
            clientName={data.name}
          />
        )}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreatePortfolioButton
            clientId={clientId}
            mutate={mutate}
            clientName={data.name}
          />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data.portfolios}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          striped
          hover
        />
      )}
    </div>
  );
}

export default Portfolios;
