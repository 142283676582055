/* eslint-disable no-shadow */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function BigSelector({ options, name, keyField, valueField, onChange }) {
  const [selected, setSelected] = useState(options[0]);
  const [isOpen, setOpen] = useState(false);

  const wrapSetSelected = (value) => {
    onChange(value);
    setOpen(false);
    return setSelected(value);
  };

  const handleClick = () => {
    if (!isOpen) {
      setOpen(true);
    }
  };

  return (
    <Listbox value={selected} onChange={wrapSetSelected}>
      <>
        <input type="hidden" name={name} value={selected[valueField]} />
        <Listbox.Label className="sr-only">
          Change published status
        </Listbox.Label>
        <div className="relative">
          <div className="inline-flex shadow-sm rounded-md divide-x divide-indigo-600 ">
            <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600 ">
              <div className="relative inline-flex items-center bg-indigo-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                <p className="ml-2.5 text-sm font-medium">{selected.title}</p>
              </div>

              <Listbox.Button
                as="span"
                className="w-fullrelative inline-flex items-center bg-indigo-500 text-sm  rounded-l-none rounded-r-md font-medium text-white hover:bg-indigo-600"
              >
                <button
                  type="button"
                  className="p-2  focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2  rounded-l-none rounded-r-md focus:ring-offset-gray-50 focus:ring-indigo-500"
                  onClick={handleClick}
                >
                  <span className="sr-only">Change published status</span>
                  <ChevronDownIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
              </Listbox.Button>
            </div>
          </div>

          <Transition
            show={isOpen}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="origin-top-left absolute z-10 mt-2 w-96 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option[keyField]}
                  className={({ active }) =>
                    classNames(
                      active ? 'text-white bg-indigo-500' : 'text-gray-900',
                      'cursor-default select-none relative p-4 text-sm'
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p
                          className={selected ? 'font-semibold' : 'font-normal'}
                        >
                          {option.title}
                        </p>
                        {selected ? (
                          <span
                            className={
                              active ? 'text-white' : 'text-indigo-500'
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                      <p
                        className={classNames(
                          active ? 'text-indigo-200' : 'text-gray-500',
                          'mt-2'
                        )}
                      >
                        {option.description}
                      </p>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    </Listbox>
  );
}

export default BigSelector;
