const confirm = (title, description) =>
  new Promise((resolve, reject) => {
    window.stores.modals.create({
      kind: 'confirmation',
      title,
      description,
      promise: {
        resolve,
        reject
      }
    });
  });

export default {
  confirm
};
