import Moment from 'react-moment';

const formatWithTime = 'DD/MM/YYYY HH:mm';
const formatWithoutTime = 'DD/MM/YYYY';

function DateTime({ children, time = true }) {
  const format = time ? formatWithTime : formatWithoutTime;
  return <Moment format={format}>{children}</Moment>;
}

export default DateTime;
