const DEFAULT_ENDPOINT =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : '/api';

export class HTTP {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  request = async (path, options) => {
    const response = await fetch(`${this.endpoint}${path}`, options);
    if (response.status === 401) {
      document.location = '/';
    }

    return response;
  };

  get = (path) =>
    this.request(path, {
      credentials: 'include',
      mode: 'cors',
      referrerPolicy: 'no-referrer-when-downgrade'
    });

  post = (path, data) =>
    this.request(path, {
      method: 'POST',
      body: JSON.stringify(data),
      credentials: 'include',
      mode: 'cors',
      referrerPolicy: 'no-referrer-when-downgrade',
      headers: {
        'Content-Type': 'application/json'
      }
    });

  put = (path, data) =>
    this.request(path, {
      method: 'PUT',
      body: JSON.stringify(data),
      credentials: 'include',
      mode: 'cors',
      referrerPolicy: 'no-referrer-when-downgrade',
      headers: {
        'Content-Type': 'application/json'
      }
    });

  patch = (path, data) =>
    this.request(path, {
      method: 'PATCH',
      body: JSON.stringify(data),
      credentials: 'include',
      mode: 'cors',
      referrerPolicy: 'no-referrer-when-downgrade',
      headers: {
        'Content-Type': 'application/json'
      }
    });

  delete = (path) =>
    this.request(path, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      referrerPolicy: 'no-referrer-when-downgrade',
      headers: {
        'Content-Type': 'application/json'
      }
    });
}

const http = new HTTP(DEFAULT_ENDPOINT);

export default http;
