import { action } from 'mobx';
import { Fragment, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, Link } from 'react-router-dom';
import StoresContext from '../../../Stores';
import HTTP from '../../../Services/HTTP';

function currencyFormat(num) {
  return `$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

function Table({ parent, items }) {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            RFC EMISOR NUEVO
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            RFC RECIPIENTE NUEVO
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            TOTAL ANTERIOR
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            % DESCUENTO
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            TOTAL DESPUES
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            STATUS
          </th>
          {/* <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            TIPO
          </th> */}
          {/* <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Certificado inicia
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Certificado vence
          </th> */}
          <th scope="col" className="relative px-6 py-3">
            <span className="sr-only">Descargar</span>
          </th>
          <th scope="col" className="relative px-6 py-3">
            <span className="sr-only">Editar</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item) => (
          <Fragment key={item.id}>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {item.newTransmitterRFC}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {item.newRecipientRFC}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {currencyFormat(Number(item.totalBefore))}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {parent.adjustPercentage}%
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {currencyFormat(Number(item.totalAfter))}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {item.status}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {item.status === 'done' && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${HTTP.endpoint}/reinvoice/download/${item.id}`}
                    className="text-indigo-500 font-medium cursor-pointer"
                  >
                    XML
                  </a>
                )}
                {item.status === 'created' && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${HTTP.endpoint}/reinvoice/download-modified/${item.id}`}
                    className="text-indigo-500 font-medium cursor-pointer"
                  >
                    XML
                  </a>
                )}
                {item.status === 'failed' && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${HTTP.endpoint}/reinvoice/download/${parent.id}/${item.id}`}
                    className="text-indigo-500 font-medium cursor-pointer"
                  >
                    ZIP
                  </a>
                )}
              </td>
              {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {
                    company.certificates.length > 0
                      ? <span>{company.certificates[0].certificateType}</span>
                      : <span className="text-orange-400">sin certificado</span>
                  }
                </td> */}
              {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {
                    company.certificates.length > 0
                      ? <span>{formatDate(getStartTimestamp(company))}</span>
                      : <span className="text-orange-400">sin certificado</span>
                  }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {
                    company.certificates.length > 0
                      ? <span>{formatDate(getExpirationTimestamp(company))}</span>
                      : <span className="text-orange-400">sin certificado</span>
                  }
                </td> */}
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                {/* <Link to={`/companies/edit/${company.id}`} className="text-indigo-600 hover:text-indigo-900">
                    Editar
                  </Link> */}

                {/* <span className="ml-4 text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={companies.delete(company)}>
                    Borrar
                  </span> */}
              </td>
            </tr>
            {item.status === 'failed' && (
              <tr>
                <td
                  className="px-6 pb-4 text-xs text-gray-900 bg-red-100"
                  colSpan={99}
                >
                  Referencia: {item.reference} {item.errorMessage}
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}

const InspectBatch = observer(() => {
  const params = useParams();
  const { reInvoice } = useContext(StoresContext);

  useEffect(() => {
    reInvoice.fetchInspectBatch(params.batchId);

    return action(() => {
      reInvoice.currentInspectBatch = null;
    });
  }, [params.batchId, reInvoice]);

  const handleBatchReset = () => {
    reInvoice.resetBatch(reInvoice.currentInspectBatch.id);
  };

  return (
    <div className="py-2">
      <div className="max-w-12xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <Link
          to="/reinvoice"
          className="text-indigo-600 text-sm hover:text-indigo-900"
        >
          &laquo; regresar
        </Link>
      </div>
      <div className="pt-1 max-w-12xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <h1 className="text-2xl font-semibold text-gray-900">
          Detalle de batch
        </h1>
        <div className="ml-4 flex-shrink-0">
          {reInvoice.currentInspectBatch &&
            reInvoice.currentInspectBatch.status === 'running' && (
              <button
                type="button"
                onClick={handleBatchReset}
                className="py-2 px-4 bg-indigo-500 rounded shadow text-white font-medium mr-4"
              >
                resetear
              </button>
            )}

          {reInvoice.currentInspectBatch &&
            (['failed', 'done'].indexOf(reInvoice.currentInspectBatch.status) >
            -1 ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${HTTP.endpoint}/reinvoice/download/batch/${params.batchId}`}
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Descargar Batch
              </a>
            ) : (
              reInvoice.currentInspectBatch.status
            ))}
        </div>
      </div>
      <div className="max-w-12xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-5">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  {reInvoice.currentInspectBatch && (
                    <Table
                      parent={reInvoice.currentInspectBatch}
                      items={reInvoice.currentInspectBatch.batchItems}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default InspectBatch;
