import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import StoresContext from '../../../Stores';
import modals from '../../../utils/modals';
import DateTime from '../../../Components/DateTime';

const columns = [
  {
    key: 'createdAt',
    label: 'Fecha Creación',
    template: (cell) => <DateTime>{cell}</DateTime>
  },
  {
    key: 'email',
    label: 'Correo electronico',
    sortable: true
  },
  {
    key: 'visibleApps',
    label: 'APLICACIONES PERMITIDAS',
    template: (cell) => cell?.split(',').join(' ')
  },
  {
    key: 'isAdmin',
    label: 'Administrador',
    template: (cell) => (cell === true ? 'Si' : 'No'),
    sortable: true
  }
];

const deleteAction = async ({ row, users, mutate }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Usuario',
    'Una vez confirmando, este usuario ya no podrá usar la plataforma.'
  );

  if (confirmed) {
    const success = await users.delete(row);
    if (success) {
      mutate();
    }
  }
};

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'edit',
      label: 'Editar',
      onClick: ({ row, navigate }) => navigate(`./edit/${row.id}`)
    },
    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreateUserButton() {
  return <LinkAddButton to="./create">Crear Usuario</LinkAddButton>;
}

function UsersList() {
  const navigate = useNavigate();
  const { users } = useContext(StoresContext);
  const { data, isLoading, isEmpty, mutate } = useAPI('/users');

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, navigate, users, mutate });

  const showTable = !isLoading && !isEmpty;

  const handleRowClick = (evt, row) => {
    navigate(`./edit/${row.id}`);
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Usuarios"
        description="Aqui puede usted administrar sus usuarios"
      >
        {!isLoading && data.length > 0 && <CreateUserButton />}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateUserButton />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          striped
          hover
        />
      )}
    </div>
  );
}

export default UsersList;

// import { Observer, observer } from 'mobx-react-lite';
// import { useContext, useLayoutEffect } from 'react';
// import { Link } from 'react-router-dom';
// import StoresContext from '../../../Stores';
// import Loading from '../../../Components/Loading';

// function Table() {
//   const { users } = useContext(StoresContext);

//   return (
//     <table className="min-w-full divide-y divide-gray-200">
//       <thead className="bg-gray-50">
//         <tr>
//           <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//             Correo electronico
//           </th>
//           <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//             Applicaciones permitidas
//           </th>
//           <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//             Administrador
//           </th>
//           <th scope="col" className="relative px-6 py-3">
//             <span className="sr-only">Editar</span>
//           </th>
//         </tr>
//       </thead>
//       <tbody className="bg-white divide-y divide-gray-200">
//         <Observer>
//           {() =>
//             users.items.map((user) => (
//               <tr key={user.id}>
//                 <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.email}</td>
//                 <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.visibleApps}</td>
//                 <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
//                   {user.isAdmin ? 'Si' : 'No'}
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
//                   <Link to={`/users/edit/${user.id}`} className="text-indigo-600 hover:text-indigo-900">
//                     Editar
//                   </Link>

//                   <button
//                     type="button"
//                     className="ml-4 text-indigo-600 hover:text-indigo-900 cursor-pointer"
//                     onClick={users.delete(user)}
//                   >
//                     Borrar
//                   </button>
//                 </td>
//               </tr>
//             ))
//           }
//         </Observer>
//       </tbody>
//     </table>
//   );
// }

// const UsersApp = observer(() => {
//   const { users } = useContext(StoresContext);

//   useLayoutEffect(() => {
//     users.fetch();
//   }, []);

//   return (
//     <div className="py-2">
//       <div className="max-w-12xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
//         <Link to="/" className="text-indigo-600 text-sm hover:text-indigo-900">
//           &laquo; regresar
//         </Link>
//       </div>
//       <div className="max-w-12xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
//         <h1 className="text-2xl font-semibold text-gray-900">Usuarios</h1>
//         <div className="ml-4 flex-shrink-0">
//           <Link
//             to="/users/create"
//             type="button"
//             className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//           >
//             Agregar Usuario
//           </Link>
//         </div>
//       </div>
//       <div className="max-w-12xl mx-auto px-4 sm:px-6 md:px-8">
//         <div className="py-4">
//           <div className="flex flex-col">
//             <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
//               <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
//                 <Observer>
//                   {() =>
//                     users.isFetching ? (
//                       <Loading />
//                     ) : (
//                       <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
//                         <Table />
//                       </div>
//                     )
//                   }
//                 </Observer>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// });

// export default UsersApp;
