import './App.css';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import moment from 'moment-timezone';

import { useContext } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Login from './Components/Login';
import StoresContext, { stores } from './Stores';
import ModalProvider from './Components/ModalProvider';

import StocksApp from './Apps/Stocks';
import CompaniesApp from './Apps/Companies';
import AppSelector from './Apps/AppSelector';
import ReInvoiceApp from './Apps/ReInvoice';
import UsersApp from './Apps/Users';
import BankingApp from './Apps/Banking';
import ReceiverCompanies from './Apps/ReceiverCompanies';

moment.tz.setDefault('America/Monterrey');

registerLocale('es', es);
setDefaultLocale('es');

const App = observer(() => {
  const { session } = useContext(StoresContext);
  return (
    <StoresContext.Provider value={stores}>
      {session.isAuthenticated ? (
        <main>
          <ModalProvider />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AppSelector />} />
              <Route path="/stocks/*" element={<StocksApp />} />
              <Route path="/companies/*" element={<CompaniesApp />} />
              <Route path="/reinvoice/*" element={<ReInvoiceApp />} />
              <Route path="/users/*" element={<UsersApp />} />
              <Route path="/banking/*" element={<BankingApp />} />
              <Route
                path="/receiver-companies/*"
                element={<ReceiverCompanies />}
              />
            </Routes>
          </BrowserRouter>
        </main>
      ) : (
        <Login session={session} />
      )}
    </StoresContext.Provider>
  );
});

export default App;
