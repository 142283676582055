import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import StoresContext from '../Stores';

function YieldModals() {
  const { modalsStore } = useContext(StoresContext);
  return modalsStore.state.currentModal;
}

export default observer(YieldModals);
