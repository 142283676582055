/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-loop-func */
import { action, makeAutoObservable, runInAction } from 'mobx';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import HTTP from '../Services/HTTP';

function parseXml(xmlStr) {
  return new window.DOMParser().parseFromString(xmlStr, 'text/xml');
}

const serial = (funcs) =>
  funcs.reduce(
    (promise, func) =>
      promise.then((result) =>
        func().then(Array.prototype.concat.bind(result))
      ),
    Promise.resolve([])
  );

class ReInvoice {
  items = [];
  currentBatch = null;
  discount = 0;
  isFetching = false;
  isReading = false;
  date = new Date();
  time = '00:00';
  uploadedFiles = 0;
  isUploadModeActive = false;
  currentInspectBatch = null;
  fetchingExistingStamps = false;
  seriesAndSheetGeneratedByERP = {};
  stampedInvoices = [];
  otherReceiptTypesObject = {};

  constructor() {
    makeAutoObservable(this);
  }

  get otherReceiptTypes() {
    const arr = [];
    Object.keys(this.otherReceiptTypesObject).forEach((type) => {
      arr.push({ label: type, count: this.otherReceiptTypesObject[type] });
    });
    return arr;
  }

  fetchInspectBatch = async (batchId) => {
    const response = await HTTP.get(`/reinvoice/inspect/${batchId}`);
    const data = await response.json();

    runInAction(() => {
      this.currentInspectBatch = data;
    });
  };

  stamp = async (batch) => {
    const response = await HTTP.get(`/reinvoice/stamp/${batch.id}`);
    const data = await response.json();

    if (data) {
      runInAction(() => {
        Object.assign(batch, data);
      });
    }
  };

  recalculate = async (batch) => {
    Object.assign(batch, { status: 're-creating' });

    const response = await HTTP.get(`/reinvoice/recalculate/${batch.id}`);
    const data = await response.json();

    if (data) {
      runInAction(() => {
        Object.assign(batch, data);
      });
    }
  };

  delete = async (batch) => {
    await HTTP.delete(`/reinvoice/${batch.id}`);
  };

  resetBatch = async (id) => {
    await HTTP.get(`/reinvoice/${id}/reset`);

    document.location.reload();
  };

  fetch = async () => {
    runInAction(() => {
      this.isFetching = true;
    });

    const response = await HTTP.get('/reinvoice');
    const data = await response.json();

    runInAction(() => {
      this.items = data;
      this.isFetching = false;
    });
  };

  // promise all: upload: 20777.726806640625 ms
  // promise series: upload: 23220.427001953125 ms
  uploadInvoices = action((newBatch, navigate) => {
    this.uploadedFiles = 0;
    this.isUploadModeActive = true;

    const allUploads = [];

    this.currentBatch.files.forEach((file) => {
      allUploads.push(async () => {
        await HTTP.post(`/reinvoice/${newBatch.id}`, {
          content: file.content,
          originalXMLFilename: file.name
        });
        runInAction(() => {
          this.uploadedFiles += 1;
        });
      });
    });

    serial(allUploads)
      .then(async () => {
        await HTTP.put(`/reinvoice/${newBatch.id}`, { status: 'created' });
        // this.resetUploadMode();
        navigate('/reinvoice');
      })
      .catch(async () => {
        await HTTP.put(`/reinvoice/${newBatch.id}`, {
          status: 'creation-failed'
        });
        // this.resetUploadMode();
        navigate('/reinvoice');
      })
      .finally(() => {
        this.resetUploadMode();
      });
  });

  resetUploadMode = action(() => {
    this.isUploadModeActive = false;
    this.currentBatch = null;
    this.isReading = false;
    this.isFetching = false;
    this.discount = 0;
    this.uploadedFiles = 0;
    this.otherReceiptTypesObject = {};
  });

  createBatch = async (newBatchData) => {
    const response = await HTTP.post('/reinvoice', newBatchData);
    const data = await response.json();

    return data;
  };

  setDate = action((date) => {
    this.date = date;
  });

  setTime = action((time) => {
    this.time = time;
  });

  setCurrentTime = () => {
    const date = moment
      .tz(new Date(), 'America/Monterrey')
      .subtract(1, 'h')
      .subtract(1, 'd');

    this.setTime(date.format('HH:mm'));
  };

  setFiles = action((files) => {
    this.currentBatch = {
      files: [],
      before: { subTotal: new Decimal(0), total: new Decimal(0) },
      after: []
    };

    this.isReading = true;

    const before = { subTotal: new Decimal(0), total: new Decimal(0) };
    const after = [];
    this.otherReceiptTypesObject = {};
    this.stampedInvoices = [];
    this.fetchingExistingStamps = false;
    this.seriesAndSheetGeneratedByERP = {};

    let readFileCounter = 0;

    // read files
    for (let i = 0; i < files.length; i += 1) {
      const reader = new FileReader();
      reader.fileName = files[i].name;

      reader.onload = action((e) => {
        const xmlString = e.target.result
          ?.replace(/(\r\n|\n|\r)/gm, '')
          .replace(/>\s*/g, '>')
          .replace(/\s*</g, '<'); // replace whitespaces

        const xmlObject = parseXml(xmlString);

        // const isNomina = xmlObject.getElementsByTagName('cfdi:Comprobante')[0].getAttribute('xmlns:nomina12');
        const receiptType = xmlObject
          .getElementsByTagName('cfdi:Comprobante')[0]
          .getAttribute('TipoDeComprobante')
          .toUpperCase(); // I, E, N - solo dejar I

        const series = xmlObject
          .getElementsByTagName('cfdi:Comprobante')[0]
          .getAttribute('Serie');
        const sheet = xmlObject
          .getElementsByTagName('cfdi:Comprobante')[0]
          .getAttribute('Folio');

        if (series?.length === 25 && sheet?.length === 40) {
          if (!this.seriesAndSheetGeneratedByERP[series]) {
            this.seriesAndSheetGeneratedByERP[series] = [];
          }

          this.seriesAndSheetGeneratedByERP[series].push({
            fileName: reader.fileName,
            sheet
          });
        }

        if (receiptType === 'I') {
          const concepts = xmlObject
            .getElementsByTagName('cfdi:Conceptos')[0]
            .getElementsByTagName('cfdi:Concepto');

          Array.from(concepts).forEach((concept) => {
            const claveProdServ = concept.getAttribute('ClaveProdServ');

            // all but nomina
            // if (claveProdServ !== '84111505') {
            const price = new Decimal(concept.getAttribute('Importe'));
            const unitPrice = new Decimal(
              concept.getAttribute('ValorUnitario')
            );
            const quantity = new Decimal(concept.getAttribute('Cantidad'));

            const taxLine = concept.childNodes[0]?.childNodes[0]?.childNodes[0];

            const taxMultiplicand = new Decimal(
              taxLine?.getAttribute('TasaOCuota') || 0
            );
            const taxValue = new Decimal(taxLine?.getAttribute('Importe') || 0);

            // console.log(price.toString());
            before.subTotal = before.subTotal.plus(price);
            // console.log(before.subTotal.toString());
            // console.log('---');
            before.total = before.total.plus(price.plus(taxValue));

            after.push({
              quantity,
              unitPrice,
              taxMultiplicand
            });
            // }
          });

          this.currentBatch.files.push({
            name: files[i].name,
            content: e.target.result
          });
        } else if (!this.otherReceiptTypesObject[receiptType]) {
          this.otherReceiptTypesObject[receiptType] = 1;
        } else {
          this.otherReceiptTypesObject[receiptType] += 1;
        }

        readFileCounter += 1;

        if (readFileCounter === files.length) {
          this.currentBatch.before.subTotal = before.subTotal;
          this.currentBatch.before.total = before.total;
          this.currentBatch.after = after;

          this.isReading = false;
        }
      });

      reader.readAsText(files[i]);
    }
  });

  checkStamped = async (companyId) => {
    runInAction(() => {
      this.fetchingExistingStamps = true;
    });

    const response = await HTTP.post(`/reinvoice/check-stamped`, {
      companyId,
      existing: this.seriesAndSheetGeneratedByERP
    });

    const stampedInvoices = await response.json();

    runInAction(() => {
      this.stampedInvoices = stampedInvoices;
      this.fetchingExistingStamps = false;
    });
  };

  setDiscount = action((evt) => {
    this.discount = evt.currentTarget.value;
  });

  get canEstimate() {
    return !this.isReading && this.currentBatch?.files.length > 0;
  }

  calculateSubTotalAfter = (accumulator, nextValue) => {
    const { quantity, unitPrice } = nextValue;
    const subTotal = quantity.times(
      unitPrice.minus(unitPrice.times(this.discountMultiplicand))
    );

    return accumulator.plus(subTotal);
  };

  get discountMultiplicand() {
    const hundred = new Decimal(100);

    const discount = new Decimal(Number(this.discount) || 0);
    return discount.dividedBy(hundred);
  }

  calculateTotalAfter = (accumulator, nextValue) => {
    const { quantity, unitPrice, taxMultiplicand } = nextValue;
    const price = quantity.times(
      unitPrice.minus(unitPrice.times(this.discountMultiplicand))
    );
    const taxes = price.times(taxMultiplicand);
    const total = price.plus(taxes);
    return accumulator.plus(total);
  };

  get subTotalAfter() {
    const subTotalAfter = new Decimal(0);
    return this.currentBatch.after.reduce(
      this.calculateSubTotalAfter,
      subTotalAfter
    );
  }

  get totalAfter() {
    const totalAfter = new Decimal(0);
    return this.currentBatch.after.reduce(this.calculateTotalAfter, totalAfter);
  }
}

export default ReInvoice;
