import { makeAutoObservable, runInAction } from 'mobx';
import HTTP from '../Services/HTTP';

export default class Clients {
  items = [];
  currentClient = null;
  currentPortfolio = [];
  isFetching = false;
  currentPortfolioName = '';
  currentClientName = '';

  constructor() {
    makeAutoObservable(this);
  }

  setFetching = (val) => {
    runInAction(() => {
      this.isFetching = val;
    });
  };

  fetchAllClients = async () => {
    this.setFetching(true);
    const response = await HTTP.get(`/stocks`);
    const data = await response.json();
    runInAction(() => {
      this.items = data;
    });
    this.setFetching(false);
  };

  fetchClientById = async (clientId) => {
    this.setFetching(true);
    const response = await HTTP.get(`/stocks/${clientId}`);
    const data = await response.json();
    runInAction(() => {
      this.currentClient = data;
    });
    this.setFetching(false);
  };

  create = async (values) => {
    const response = await HTTP.post(`/stocks`, values);
    const data = await response.json();

    return data;
  };

  createPortfolio = async (clientId, values) => {
    const response = await HTTP.post(`/stocks/${clientId}/portfolios`, values);
    const data = await response.json();

    this.currentClient?.portfolios.push(data);

    return data;
  };

  fetchClientWithPortfolioById = async (clientId, portfolioId) => {
    this.setFetching(true);
    const response = await HTTP.get(
      `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords`
    );
    const data = await response.json();
    runInAction(() => {
      this.currentPortfolio = data.portfolio;
      this.currentPortfolioName = data.portfolioName;
      this.currentClientName = data.clientName;
    });
    this.setFetching(false);
  };

  resetCurrentClient = () => {
    this.currentClient = null;
  };

  createStockRecord = async (clientId, portfolioId, values) => {
    const response = await HTTP.post(
      `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords`,
      values
    );
    const data = await response.json();

    return data;
  };

  delete = async (client) => {
    const response = await HTTP.delete(`/stocks/${client.id}`);
    const data = await response.json();
    return data;
  };

  deletePortfolio = async (clientId, portfolio) => {
    const response = await HTTP.delete(
      `/stocks/${clientId}/portfolios/${portfolio.id}`
    );
    const data = await response.json();
    return data;
  };
}
