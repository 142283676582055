import { PlusIcon } from '@heroicons/react/solid';
import LinkButton from '../Primitives/LinkButton';

function LinkAddButton({ to, children }) {
  return (
    <LinkButton to={to}>
      <PlusIcon className="w-5 h-5" /> <span className="ml-2">{children}</span>
    </LinkButton>
  );
}

export default LinkAddButton;
