import CurrencyFormat from 'react-currency-format';

function NumberFormat({ children }) {
  return (
    <CurrencyFormat
      value={children}
      decimalScale={2}
      thousandSeparator
      displayType="text"
      fixedDecimalScale
    />
  );
}

export default NumberFormat;
