/* eslint-disable react/jsx-props-no-spreading */
import classNames from '../utils/classNames';

function Button({ children, className = '', ...props }) {
  const classes = classNames(
    className,
    'inline-flex transition-colors items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 disabled:text-gray-400 disabled:bg-gray-300 hover:text-indigo-800 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300'
  );

  return (
    <button type="button" className={classes} {...props}>
      {children}
    </button>
  );
}

export default Button;
