import { useNavigate, useParams } from 'react-router-dom';

import { PrinterIcon } from '@heroicons/react/solid';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import Button from '../../../Primitives/Button';
import useAPINew from '../../../hooks/useAPINew';
import { MenuConfig } from '../../../Components/ActionMenu';
import modals from '../../../utils/modals';
import http from '../../../Services/HTTP';

const columns = [
  {
    key: 'paymentDateTransmitter',
    label: 'Fecha de pago emisor',
    type: 'date',
    filterable: true,
    template: (cell) => cell.split('T')[0],
    sortable: true
  },
  {
    key: 'portfolio',
    label: 'Portafolio',
    template: (cell, row) => row.Portfolio.name
  },
  {
    key: 'stockName',
    label: 'Nombre',
    sortable: true
  },
  {
    key: 'shareQuantity',
    label: 'Numero de acciones',
    sortable: true,
    type: 'number',
    footerSum: true
  },
  {
    key: 'dividendPerShare',
    label: 'Dividendo por accion',
    type: 'number',
    sortable: true
  },
  {
    key: 'totalDividend',
    label: 'Total Dividendo',
    type: 'number',
    sortable: true,
    footerSum: true
  },
  {
    key: 'retentionPercentage',
    label: '% Rentención',
    type: 'number',
    template: (cell) => `${cell}%`,
    sortable: true
  },
  {
    key: 'totalRetention',
    label: 'Total Retención',
    type: 'number',
    sortable: true,
    footerSum: true
  },
  {
    key: 'otherFees',
    label: 'Comisiones',
    type: 'number',
    sortable: true,
    footerSum: true
  },
  {
    key: 'totalPaid',
    label: 'Total Pagado',
    type: 'number',
    sortable: true,
    footerSum: true
  }
];

const deleteAction = async ({ row, clients, mutate }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Dividendo',
    'Estas seguro que deseas borrar este Dividendo?'
  );
  if (confirmed) {
    await http.delete(`/stocks/dividends/${row.id}`);
    mutate();
  }
};

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function ClientDividendsList() {
  const navigate = useNavigate();

  const { clientId, portfolioId } = useParams();

  const [
    dividends,
    isLoadingDividends,
    isDividendsEmpty,
    isDividendsError,
    mutate
  ] = useAPINew(`/stocks/${clientId}/dividends`);

  rowMenuConfig.onClick = (onClick, row) => onClick({ row, navigate, mutate });

  const showTable = !isLoadingDividends && !isDividendsEmpty;

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/stocks/clients"
        title="Dividendos"
        description={
          isLoadingDividends
            ? 'cargando'
            : `Esta viendo los dividendos del cliente xxx`
        }
      >
        <Button onClick={window.print}>
          <PrinterIcon className="h-5 w-5" />
          <span className="ml-2">Imprimir</span>
        </Button>
      </TablePageHeading>

      {isLoadingDividends && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isDividendsEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          No hay dividendos para este cliente
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={dividends}
          rowMenuConfig={rowMenuConfig}
          rowKey="id"
          hover
        />
      )}
    </div>
  );
}

export default ClientDividendsList;
