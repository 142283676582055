import useSWR from 'swr';

import http from '../Services/HTTP';

const fetcher = (url) => http.get(url).then((response) => response.json());

const useAPI = (path) => {
  const { data, error, mutate } = useSWR(path, fetcher);
  const isEmpty = data && data.length === 0;
  const reload = () => mutate();

  return {
    data,
    isLoading: !error && !data,
    isEmpty,
    isError: !!error,
    error,
    mutate,
    reload
  };
};

export default useAPI;
