import serialize from 'form-serialize';
import { makeAutoObservable, runInAction } from 'mobx';
import HTTP from '../Services/HTTP';

export default class Session {
  isAuthenticated = false;
  isAdmin = false;
  visibleApps = [];

  constructor() {
    makeAutoObservable(this);
    this.evaluateCookieDomain();
  }

  evaluateCookieDomain = () => {
    const sessionId = document.cookie.split('erp.sessionId=')[1]?.split(';')[0];
    if (sessionId) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }
  };

  login = async (evt) => {
    evt.preventDefault();

    const data = serialize(evt.currentTarget, { hash: true });

    const response = await HTTP.post('/session/login', data);
    const { success, isAdmin, visibleApps } = await response.json();

    if (success) {
      runInAction(() => {
        this.isAdmin = isAdmin;
        this.isAuthenticated = true;
        this.visibleApps = visibleApps.split(',');
      });
    }

    return false;
  };

  logout = () => {
    runInAction(() => {
      this.isAuthenticated = false;
    });
  };
}
