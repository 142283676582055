import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import StoresContext from '../../../Stores';
import modals from '../../../utils/modals';
import DateTime from '../../../Components/DateTime';

const status2mex = {
  done: 'timbrado',
  created: 'creado',
  creating: 'creando',
  running: 'timbrando',
  failed: 'fallado',
  'creation-failed': 'creación fallada',
  're-creating': 'recreando'
};

const columns = [
  {
    key: 'createdAt',
    label: 'Fecha creación',
    sortable: true,
    template: (cell) => <DateTime>{cell}</DateTime>
  },
  {
    key: 'transmitterCompanyName',
    label: 'Nombre Emisor',
    sortable: true,
    template: (cell, row) => row.transmitterCompany?.name
  },
  {
    key: 'transmitterCompanyRFC',
    label: 'RFC Emisor',
    sortable: true,
    template: (cell, row) => row.transmitterCompany?.rfc
  },
  {
    key: 'recipientName',
    label: 'Nombre Receptor',
    sortable: true,
    template: (cell, row) => row.batchItems[0]?.newRecipientName
  },
  {
    key: 'recipientRFC',
    label: 'RFC Receptor',
    sortable: true,
    template: (cell, row) => row.batchItems[0]?.newRecipientRFC
  },
  {
    key: 'status',
    label: 'Estatus',
    template: (cell) => status2mex[cell]
  }
];

const deleteAction = async ({ row, reInvoice, mutate }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Batch',
    'Estas seguro que deseas borrar este Batch?'
  );
  if (confirmed) {
    await reInvoice.delete(row);
    mutate();
  }
};

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'open',
      label: 'Abrir',
      onClick: ({ row, navigate }) => navigate(`/reinvoice/view/${row.id}`)
    },
    {
      key: 'stamp',
      label: 'Timbrar',
      validator: ({ row }) => row.status === 'created',
      onClick: ({ row, reInvoice }) => {
        reInvoice.stamp(row);
      }
    },
    {
      key: 'recalculate',
      label: 'Recalcular fallados',
      validator: ({ row }) => ['created', 'failed'].includes(row.status),
      onClick: ({ row, reInvoice }) => {
        reInvoice.recalculate(row);
      }
    },
    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction,
      validator: ({ row }) => row.status !== 'done'
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreateBatchButton() {
  return <LinkAddButton to="/reinvoice/create">Agregar Batch</LinkAddButton>;
}

function BatchList() {
  const navigate = useNavigate();
  const { reInvoice } = useContext(StoresContext);
  const { data, isLoading, isEmpty, mutate } = useAPI('/reinvoice');

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, navigate, reInvoice, mutate });

  const showTable = !isLoading && !isEmpty;

  const handleRowClick = (evt, row) => {
    navigate(`/reinvoice/view/${row.id}`);
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Facturar en batch"
        description="Un listado de clientes, haga click en uno para ver y manejar sus portafolios, o crea un cliente nuevo haciendo click en el boton."
      >
        {!isLoading && data.length > 0 && <CreateBatchButton />}
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isEmpty && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateBatchButton />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={rowMenuConfig}
          onRowClick={handleRowClick}
          rowKey="id"
          striped
          hover
        />
      )}
    </div>
  );
}

export default BatchList;
