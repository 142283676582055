import { Link } from 'react-router-dom';

function TablePageHeading({ backUrl, title, description, children }) {
  return (
    <div className="print:hidden mt-2 mb-5 border-b border-gray-200">
      <div className="max-w-12xl mx-auto flex items-center justify-between flex-wrap sm:flex-nowrap">
        <Link
          to={backUrl}
          className="text-indigo-600 text-sm hover:text-indigo-900"
        >
          &laquo; regresar
        </Link>
      </div>
      <div className="max-w-12xl mx-auto mb-4 mt-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div>
          <h1 className="text-lg font-medium text-gray-900">{title}</h1>
          <p className="max-w-4xl text-sm text-gray-500">{description}</p>
        </div>
        <div className="flex-shrink-0">{children}</div>
      </div>
    </div>
  );
}

export default TablePageHeading;
