/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import Decimal from 'decimal.js';
import { action, extendObservable } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import propTypes from 'prop-types';
import { useLayoutEffect } from 'react';
import NumberFormat from '../../Primitives/NumberFormat';
import classNames from '../../utils/classNames';
import ActionMenu from '../ActionMenu';
import FilterBar from './FilterBar';

function TH({ children, className = '', sortable, isActive, type, ...props }) {
  const defaultClasses =
    'px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider';
  const newClassName = classNames(
    className,
    isActive ? 'bg-zinc-100' : 'bg-gray-50',
    type === 'number' ? 'text-right' : '',
    defaultClasses
  );

  return (
    <th scope="col" className={newClassName} {...props}>
      {children}
    </th>
  );
}

const calculateFooterSum = (rows, sumKey, footerSum) => {
  let sum = new Decimal(0);

  if (typeof footerSum === 'function') {
    sum = rows.reduce(footerSum, sum);
  } else {
    rows.slice().forEach((row) => {
      sum = sum.plus(row[sumKey] || 0);
    });
  }

  return sum.toString();
};

const getStripedIndex = (index) =>
  index % 2 === 0 ? 'bg-white' : 'bg-gray-50';
const getHoverStriped = (index) =>
  index % 2 === 0
    ? 'bg-white hover:bg-gray-100'
    : 'bg-gray-50 hover:bg-gray-100';

const getStripedClassName = (index, hover) =>
  hover ? getHoverStriped(index) : getStripedIndex(index);

function SortableIndicator({ onClick, isActive, sortDirection }) {
  const arrowUpClassName = classNames(
    isActive && sortDirection === 'ASC'
      ? 'text-gray-500'
      : 'text-gray-300 hover:text-gray-400',
    'h-5 w-5 px-1 pt-1 relative bottom-[-1px]'
  );

  const arrowDownClassName = classNames(
    isActive && sortDirection === 'DESC'
      ? 'text-gray-500'
      : 'text-gray-300 hover:text-gray-400',
    'h-5 w-5 px-1 pb-1 relative top-[-1px]'
  );

  const containerClassName = classNames(
    !isActive && 'hidden',
    'flex-col absolute right-[-16px]  group-hover:flex'
  );

  const handleSortDirectionChange = (type) => (evt) => {
    evt.stopPropagation();
    onClick(evt, type);
  };

  return (
    <span className={containerClassName}>
      <ArrowUpIcon
        className={arrowUpClassName}
        onClick={handleSortDirectionChange('ASC')}
      />
      <ArrowDownIcon
        className={arrowDownClassName}
        onClick={handleSortDirectionChange('DESC')}
      />
    </span>
  );
}

const classNameByType = {
  undefined: 'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900',
  book: 'px-6 py-4 whitespace-normal text-sm font-medium text-gray-900',
  date: 'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900',
  number:
    'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right'
};

function Table({
  columns = [],
  rowMenuConfig,
  rowKey,
  rows = [],
  striped = false,
  hover = false,
  onRowClick
}) {
  const filterColumns = columns.filter(({ filterable }) => !!filterable);

  const state = useLocalObservable(() => ({
    sortByColumn: undefined,
    rows: [],
    sortDirection: 'ASC',
    filters: filterColumns,
    resetFilters: (filter, filterNames) => () => {
      filterNames.forEach((filterName) => {
        delete filter[filterName];
      });
    },
    setFilter: (filter, key) =>
      action((value) => {
        if (!filter[key]) {
          extendObservable(filter, { [key]: value });
        } else {
          filter[key] = value;
        }
      }),
    setSortByColumn: (column, type) => {
      if (!type) {
        if (
          !state.sortByColumn ||
          state.sortByColumn?.key !== column.key ||
          state.sortDirection === 'DESC'
        ) {
          state.sortDirection = 'ASC';
        } else {
          state.sortDirection = 'DESC';
        }
      } else {
        state.sortDirection = type;
      }
      state.sortByColumn = column;
    },
    setRows: (rawRows) => {
      state.rows = rawRows;
    },
    get sortedRows() {
      let result = state.rows;

      if (state.sortByColumn) {
        // sort
        const sortKey = state.sortByColumn.key;
        const fieldType = state.sortByColumn.type;
        const { sortValue } = state.sortByColumn;
        const { sortDirection } = state;

        result = state.rows.slice().sort((row, nextRow) => {
          const value = sortValue ? sortValue(row[sortKey], row) : row[sortKey];
          const nextValue = sortValue
            ? sortValue(nextRow[sortKey], nextRow)
            : nextRow[sortKey];

          if (fieldType === 'date') {
            if (sortDirection === 'ASC') {
              return new Date(value) > new Date(nextValue) ? 1 : -1;
            }
            return new Date(value) < new Date(nextValue) ? 1 : -1;
          }
          if (sortDirection === 'ASC') {
            return value > nextValue ? 1 : -1;
          }
          return value < nextValue ? 1 : -1;
        });
      }

      // filter
      if (state.filters.length > 0) {
        const filteredRows = result.filter((row) => {
          let match = true;

          state.filters.forEach((filter) => {
            if (filter.type === 'date') {
              const cellValue = new Date(row[filter.key]);

              if (
                cellValue < filter.rangeStartDate ||
                cellValue > filter.rangeEndDate
              ) {
                match = false;
              }
            }
          });

          return match;
        });

        return filteredRows;
      }
      return result;
    }
  }));

  useLayoutEffect(() => {
    state.setRows(rows);
    return () => {
      state.setRows([]);
    };
  }, [rows]);

  const hasActions = rowMenuConfig?.actions.length > 0;

  const hasFooterSums =
    columns.filter(({ footerSum }) => !!footerSum).length > 0;

  const handleRowClick = (row) => (evt) => {
    if (onRowClick) {
      if (
        typeof evt.target.className === 'string' &&
        evt.target.className.indexOf('action-related') === -1
      ) {
        onRowClick(evt, row);
      }
    }
  };

  const handleSortClick = (column) => (evt, direction) => {
    if (!column.sortable) return;
    state.setSortByColumn(column, direction);
  };

  return (
    <div className="flex flex-col">
      <Observer>
        {() =>
          state.filters.length > 0 && (
            <div className="flex justify-between items-center">
              <span className="">{state.sortedRows.length} registros</span>
              <span className="self-end pb-5">
                <FilterBar columns={columns} tableState={state} />
              </span>
            </div>
          )
        }
      </Observer>
      <div className="-my-2 overflow-x-auto print:overflow-x-visible sm:-mx-6 lg:-mx-8">
        <div className="print:px-0 mb-32 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <Observer>
                    {() =>
                      columns.map((column) => (
                        <TH
                          key={column.key}
                          isActive={state.sortByColumn?.key === column.key}
                          sortable={column.sortable}
                          onClick={handleSortClick(column)}
                          className="group cursor-pointer"
                        >
                          <div className="flex items-center justify-between relative">
                            {column.label}{' '}
                            {column.sortable && (
                              <SortableIndicator
                                isActive={
                                  state.sortByColumn?.key === column.key
                                }
                                sortDirection={state.sortDirection}
                                onClick={handleSortClick(column)}
                                column={column}
                              />
                            )}
                          </div>
                        </TH>
                      ))
                    }
                  </Observer>
                  {hasActions && (
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>

              <tbody
                className={striped ? '' : 'bg-white divide-y divide-gray-200'}
              >
                <Observer>
                  {() =>
                    state.sortedRows.map((row, rowIndex) => (
                      <tr
                        key={row[rowKey] || rowIndex}
                        onClick={handleRowClick(row)}
                        className={classNames(
                          striped
                            ? getStripedClassName(rowIndex, hover)
                            : 'bg-white',
                          hover && 'cursor-pointer',
                          hover &&
                            !striped &&
                            'hover:bg-gray-100 transition-colors'
                        )}
                      >
                        {columns.map((column) => (
                          <td
                            key={`${row[rowKey]}-${column.key}`}
                            className={classNameByType[column.type]}
                          >
                            {column.template
                              ? column.template(row[column.key], row)
                              : row[column.key]}
                          </td>
                        ))}
                        {hasActions && (
                          <td className="print:hidden px-6 py-4 flex items-center justify-end whitespace-nowrap text-right text-sm font-medium">
                            <ActionMenu menuConfig={rowMenuConfig} row={row} />
                          </td>
                        )}
                      </tr>
                    ))
                  }
                </Observer>
              </tbody>
              <Observer>
                {() =>
                  hasFooterSums && (
                    <tfoot>
                      <tr className="bg-gray-50">
                        {columns.map((column, index) => (
                          <td
                            key={`footer-${column.key}`}
                            className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900 font-bold text-right"
                          >
                            {index === 0 && <div>TOTAL</div>}
                            {column.footerSum && (
                              <NumberFormat>
                                {calculateFooterSum(
                                  state.sortedRows,
                                  column.key,
                                  column.footerSum
                                )}
                              </NumberFormat>
                            )}
                          </td>
                        ))}
                        {hasActions && <td />}
                      </tr>
                    </tfoot>
                  )
                }
              </Observer>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const { arrayOf, string, number, oneOfType, object, bool, func, shape } =
  propTypes;

const keyType = oneOfType([string, number]);
const labelType = oneOfType([string, number]);

Table.propTypes = {
  columns: arrayOf(
    shape({
      key: keyType,
      label: labelType
    })
  ),
  actions: arrayOf(
    shape({
      key: keyType,
      label: labelType,
      validator: func,
      onClick: func
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  rows: arrayOf(object),
  striped: bool
};

export default Table;
