import {
  CurrencyDollarIcon,
  KeyIcon,
  ReceiptTaxIcon,
  ChevronRightIcon,
  UserGroupIcon,
  BookOpenIcon,
  OfficeBuildingIcon
} from '@heroicons/react/outline';
import { useLayoutEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { LogoutIcon } from '@heroicons/react/solid';
import HTTP from '../Services/HTTP';

export const availableApps = [
  {
    label: 'Impuesto sobre ganancia de capital',
    key: 'stocks',
    route: '/stocks/clients',
    icon: CurrencyDollarIcon,
    description:
      'Heramienta para observar y calcular los impuestos minimo de pagar sobre ganancia de capital con acciones.'
  },
  {
    label: 'Estados de cuenta',
    key: 'banks',
    route: '/banking/account-statements',
    icon: BookOpenIcon,
    description: 'Gerencia de cuentas bancarias de empresas'
  },
  {
    label: 'Empresas emisoras y certificados',
    key: 'transmitterCompanies',
    route: '/companies',
    icon: KeyIcon,
    description:
      'Catalogo de empresas emisoras y sus certificados, con alertas de sus caducacion.'
  },
  {
    label: 'Empresas receptor',
    key: 'receiverCompanies',
    route: '/receiver-companies',
    icon: OfficeBuildingIcon,
    description: 'Catalogo de empresas que funcionan como receptor de facturas.'
  },
  {
    label: 'Facturación',
    key: 'reinvoice',
    route: '/reinvoice',
    icon: ReceiptTaxIcon,
    description:
      'Heramienta para crear facturas en batch, usando batch de facturas existentes, aplicando un descuento para todos los conceptos.'
  },

  {
    label: 'Administración de usuarios',
    key: 'users',
    route: '/users',
    icon: UserGroupIcon,
    description:
      'Para llevar el control sobre los usuarios que tienen acceso a esta plataforma.'
  }
];

function AppSelector() {
  const state = useLocalObservable(() => ({
    visibleApps: [],
    get apps() {
      return availableApps.filter((app) => {
        let found = false;
        state.visibleApps.forEach((visibleApp) => {
          if (visibleApp === app.key) {
            found = true;
          }
        });

        return found;
      });
    },
    fetchVisibleApps: async () => {
      const response = await HTTP.get(`/session/apps`);
      const json = await response.json();
      state.visibleApps = json.visibleApps;
    }
  }));

  useLayoutEffect(() => {
    state.fetchVisibleApps();
  }, []);

  return (
    <div className="absolute w-screen h-screen">
      <div className="container flex mt-10 justify-between items-center mx-auto">
        <h2 className="ml-24 mb-0 text-3xl font-semibold text-gray-600">
          Eliga la App que quieres abrir
        </h2>
        <a
          href="/api/session/logout"
          className="mr-24 flex items-center hover:underline text-gray-600 text-xs font-medium"
        >
          cerrar sesión
          <LogoutIcon className="h-4 w-4 ml-2" />
        </a>
      </div>
      <div className="container mx-auto">
        <div className="bg-white m-24 mt-10 shadow overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {state.apps.map((app) => (
              <li key={app.label}>
                <Link to={app.route} className="block hover:bg-gray-50">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0 text-gray-400 h-6 w-6">
                        <app.icon />
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="text-sm font-medium text-indigo-600">
                            {app.label}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {app.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default observer(AppSelector);
