import useSWR from 'swr';

import http from '../Services/HTTP';

const fetcher = (url) => http.get(url).then((response) => response.json());

const useAPINew = (path) => {
  const { data, error, mutate } = useSWR(path, fetcher);
  const isEmpty = data && data.length === 0;

  return [data, !error && !data, isEmpty, error, mutate];
};

export default useAPINew;
