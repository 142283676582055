import { Routes, Route } from 'react-router-dom';

import CompaniesList from './Pages/CompaniesList';

function CompaniesApp() {
  return (
    <main className="flex-1 h-screen">
      <Routes>
        <Route path="/" element={<CompaniesList />} />
      </Routes>
    </main>
  );
}

export default CompaniesApp;
