/* eslint-disable react/no-array-index-key */
import serialize from 'form-serialize';
import { useLocalObservable, Observer } from 'mobx-react-lite';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Decimal } from 'decimal.js';
import ReactDatePicker from 'react-datepicker';
import MXFlag from 'svg-country-flags/svg/mx.svg';
import USFlag from 'svg-country-flags/svg/us.svg';
import { runInAction } from 'mobx';
import moment from 'moment';
import BigSelector from '../../../Components/BigSelector';
import useAPINew from '../../../hooks/useAPINew';
import CurrencyInput from '../../../Components/CurrencyInput';
import http from '../../../Services/HTTP';
import Divider from '../../../Primitives/Divider';

const mapDividendsToOptions = (dividends) =>
  dividends.map((dividend, index) => ({
    cash_amount: dividend.cash_amount,
    ex_dividend_date: dividend.ex_dividend_date,
    pay_date: dividend.pay_date,
    declaration_date: dividend.declaration_date,
    record_date: dividend.record_date,
    quantityAtExDate: dividend.quantityAtExDate,
    exchangeRateAtExDate: dividend.exchangeRateAtExDate,

    index,
    title: dividend.cash_amount,
    description: (
      <span className="grid grid-cols-4 gap-x-2 text-xs">
        <span className="font-semibold">Declaración</span>
        <span>{dividend.declaration_date}</span>
        <span className="font-semibold">Corte</span>
        <span>{dividend.ex_dividend_date}</span>
        <span className="font-semibold">Pago</span>
        <span>{dividend.pay_date}</span>
        <span className="font-semibold">Acciones</span>
        <span>{dividend.quantityAtExDate}</span>
      </span>
    )
  }));

function CreateClient() {
  const navigate = useNavigate();

  const localState = useLocalObservable(() => ({
    dividendPerShare: new Decimal(0),
    quantity: new Decimal(0),
    currency: 'USD',
    exchangeCurrency: 'USD',
    exchangeRate: 1,
    otherFeesUSD: new Decimal(0),
    otherFeesMXN: new Decimal(0),
    retentionPercentage: new Decimal(0),
    paymentDateTransmitter: undefined,
    paymentDateAccountStatement: undefined,
    dividendCurrency: 'USD',
    cutOffDate: undefined,
    setOtherFeesUSD: (evt) => {
      localState.otherFeesUSD = new Decimal(evt.target.value || 0);
      const mxn = new Decimal(evt.target.value || 0);
      localState.otherFeesMXN = mxn.times(localState.exchangeRate);
    },
    setOtherFeesMXN: (evt) => {
      localState.otherFeesMXN = new Decimal(evt.target.value || 0);
    },
    setExchangeRate: (value) => {
      localState.exchangeRate = new Decimal(value || 0);
      localState.otherFeesMXN = localState.otherFeesUSD.times(
        localState.exchangeRate
      );
    },
    get totalDividendUSD() {
      return localState.quantity.times(localState.dividendPerShare).toDP(2);
    },
    get totalDividendMXN() {
      return localState.quantity
        .times(localState.dividendPerShare)
        .times(localState.exchangeRate)
        .toDP(2);
    },
    get totalRetentionUSD() {
      const multiplier = localState.retentionPercentage / 100;
      return localState.totalDividendUSD.times(multiplier).toDP(2);
    },
    get totalRetentionMXN() {
      const multiplier = localState.retentionPercentage / 100;
      return localState.totalDividendMXN.times(multiplier).toDP(2);
    },
    get totalPaidUSD() {
      return localState.totalDividendUSD
        .minus(localState.totalRetentionUSD)
        .minus(localState.otherFeesUSD)
        .toDP(2);
    },
    get totalPaidMXN() {
      return localState.totalDividendMXN
        .minus(localState.totalRetentionMXN)
        .minus(localState.otherFeesMXN)
        .toDP(2);
    },
    setCurrency: (evt) => {
      localState.currency = evt.target.value;
    },
    setExchangeCurrency: (evt) => {
      localState.exchangeCurrency = evt.target.value;
    },
    setQuantity: (evt) => {
      if (evt?.target) {
        localState.quantity = new Decimal(evt.target.value || 0);
      } else {
        localState.quantity = new Decimal(evt);
      }
    },
    setDividendPerShare: (value) => {
      if (value?.target) {
        localState.dividendPerShare = new Decimal(value.target.value);
      } else {
        localState.dividendPerShare = new Decimal(value.cash_amount);
        localState.quantity = new Decimal(value.quantityAtExDate || 0);
        localState.paymentDateTransmitter = new Date(value.pay_date);
        localState.paymentDateAccountStatement = new Date(value.pay_date);
        localState.cutOffDate = new Date(value.ex_dividend_date);
        localState.setExchangeRate(value.exchangeRateAtExDate);
      }
    },
    setRetentionPercentage: (evt) => {
      localState.retentionPercentage = evt.target.value;
    },
    changeEDCDate: (date) => {
      localState.paymentDateAccountStatement = date;
    },
    changeTransmitterDate: async (date) => {
      runInAction(() => {
        localState.exchangeRate = 0;
        localState.paymentDateTransmitter = date;
      });
      // get exChangeRate
      const response = await http.get(
        `/stocks/exchange-rate?date=${moment(date).format('YYYY-MM-DD')}`
      );
      const exchangeRateJSON = await response.json();

      runInAction(() => {
        localState.exchangeRate = exchangeRateJSON.result;
      });
    },
    changeCutOffDate: (date) => {
      localState.cutOffDate = date;
    },
    setDividendCurrency: (evt) => {
      localState.dividendCurrency = evt.target.value;
      if (localState.dividendCurrency === 'MXN') {
        localState.setExchangeRate(1);
      }
    }
  }));

  const { clientId, portfolioId, stockRecordId } = useParams();

  const [
    stockRecordDetail,
    isLoadingStockRecordDetail,
    isStockRecordDetailEmpty
  ] = useAPINew(
    `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords/${stockRecordId}/detail`
  );

  if (!isLoadingStockRecordDetail && !isStockRecordDetailEmpty) {
    if (stockRecordDetail.dividends) {
      localState.paymentDateTransmitter = new Date(
        stockRecordDetail.dividends[0].pay_date
      );
      localState.paymentDateAccountStatement = new Date(
        stockRecordDetail.dividends[0].pay_date
      );
      localState.dividendPerShare = new Decimal(
        stockRecordDetail.dividends[0].cash_amount
      );
      localState.cutOffDate = new Date(
        stockRecordDetail.dividends[0].ex_dividend_date
      );

      localState.setQuantity(stockRecordDetail.dividends[0].quantityAtExDate);
      localState.setExchangeRate(
        stockRecordDetail.dividends[0].exchangeRateAtExDate
      );
      localState.setDividendCurrency({ target: { value: 'USD' } });
    } else if (stockRecordDetail.quantity) {
      localState.setQuantity(stockRecordDetail.quantity);
    }
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const data = serialize(evt.currentTarget, { hash: true });
    await http.post(
      `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords/${stockRecordId}/dividends`,
      data
    );
    navigate('./..');
    return false;
  };

  if (isLoadingStockRecordDetail) {
    return 'loading';
  }

  return (
    <div className="py-6 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <h1 className="text-lg font-medium text-gray-900">
            Registrar Dividendo
          </h1>
        </div>
        <div>
          <div className="flex flex-col">
            <form
              className="space-y-8 divide-y divide-gray-200"
              onSubmit={handleSubmit}
            >
              <div className="space-y-8 divide-y divide-gray-200">
                <div>
                  <p className="max-w-2xl text-sm text-gray-500">
                    La informacion que usted propone aqui, se usara para crear
                    un nuevo registro de dividendo.
                  </p>
                  <div className=" bg-white rounded  shadow  py-6 px-6 mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
                    <div className="grid grid-cols-4 gap-x-4">
                      <div>
                        <label
                          htmlFor="dividendPerShare"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Dividendo por acción
                        </label>
                        <div className="mt-1">
                          {stockRecordDetail.dividends ? (
                            <BigSelector
                              name="dividendPerShare"
                              options={mapDividendsToOptions(
                                stockRecordDetail.dividends
                              )}
                              keyField="index"
                              onChange={localState.setDividendPerShare}
                              valueField="title"
                            />
                          ) : (
                            <input
                              type="text"
                              name="dividendPerShare"
                              id="dividendPerShare"
                              onChange={localState.setDividendPerShare}
                              className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="cutOffDate"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fecha de corte
                        </label>
                        <Observer>
                          {() => (
                            <ReactDatePicker
                              dateFormat="yyyy-MM-dd"
                              name="cutOffDate"
                              onChange={localState.changeCutOffDate}
                              selected={localState.cutOffDate}
                              className="mt-1 w-full block pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Observer>
                      </div>
                      <div>
                        <label
                          htmlFor="paymentDateTransmitter"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fecha de pago emisora
                        </label>
                        <Observer>
                          {() => (
                            <ReactDatePicker
                              dateFormat="yyyy-MM-dd"
                              name="paymentDateTransmitter"
                              onChange={localState.changeTransmitterDate}
                              selected={localState.paymentDateTransmitter}
                              className="mt-1 w-full block pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Observer>
                      </div>
                      <div>
                        <label
                          htmlFor="paymentDateAccountStatement"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Fecha de pago estado de cuenta
                        </label>
                        <Observer>
                          {() => (
                            <ReactDatePicker
                              dateFormat="yyyy-MM-dd"
                              name="paymentDateAccountStatement"
                              onChange={localState.changeEDCDate}
                              selected={localState.paymentDateAccountStatement}
                              className="mt-1 w-full pl-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            />
                          )}
                        </Observer>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-x-4">
                      <div>
                        <label
                          htmlFor="dividendCurrency"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tipo moneda del dividendo
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Observer>
                            {() => (
                              <select
                                name="dividendCurrency"
                                id="dividendCurrency"
                                onChange={localState.setDividendCurrency}
                                value={localState.dividendCurrency}
                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              >
                                <option value="USD">USD</option>
                                <option value="MXN">MXN</option>
                              </select>
                            )}
                          </Observer>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="shareQuantity"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Numero de acciones
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Observer>
                            {() => (
                              <input
                                type="number"
                                name="shareQuantity"
                                id="shareQuantity"
                                onChange={localState.setQuantity}
                                value={localState.quantity}
                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              />
                            )}
                          </Observer>
                        </div>
                      </div>
                      <Observer>
                        {() => (
                          <div>
                            {localState.dividendCurrency === 'USD' && (
                              <>
                                <label
                                  htmlFor="exchangeRate"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Tipo de cambio
                                </label>

                                <CurrencyInput
                                  name="exchangeRate"
                                  readOnly
                                  value={localState.exchangeRate}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </Observer>
                      <div>
                        <label
                          htmlFor="retentionPercentage"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Retenciones en %
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Observer>
                            {() => (
                              <input
                                type="text"
                                name="retentionPercentage"
                                id="retentionPercentage"
                                onChange={localState.setRetentionPercentage}
                                value={localState.retentionPercentage}
                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                              />
                            )}
                          </Observer>
                        </div>
                      </div>
                    </div>
                    <Observer>
                      {() =>
                        localState.dividendCurrency === 'USD' && (
                          <>
                            <Divider>
                              <div className="flex items-center">
                                <img
                                  alt="us-flag"
                                  src={USFlag}
                                  className="h-3 w-5 mr-2 rounded-sm shadow"
                                />{' '}
                                Totales USD
                              </div>
                            </Divider>
                            <div className="grid grid-cols-4 gap-x-4">
                              <div>
                                <label
                                  htmlFor="totalDividend"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Dividendos
                                </label>
                                <Observer>
                                  {() => (
                                    <CurrencyInput
                                      readOnly
                                      name="totalDividend"
                                      value={localState.totalDividendUSD}
                                    />
                                  )}
                                </Observer>
                              </div>
                              <div>
                                <label
                                  htmlFor="totalRetention"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Retención
                                </label>

                                <Observer>
                                  {() => (
                                    <CurrencyInput
                                      readOnly
                                      name="totalRetention"
                                      value={localState.totalRetentionUSD}
                                    />
                                  )}
                                </Observer>
                              </div>

                              <div>
                                <label
                                  htmlFor="otherFees"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Comisiones
                                </label>

                                <Observer>
                                  {() => (
                                    <CurrencyInput
                                      name="otherFees"
                                      onChange={localState.setOtherFeesUSD}
                                      value={localState.otherFeesUSD}
                                    />
                                  )}
                                </Observer>
                              </div>

                              <div>
                                <label
                                  htmlFor="totalPaid"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Pagado
                                </label>

                                <Observer>
                                  {() => (
                                    <CurrencyInput
                                      readOnly
                                      name="totalPaid"
                                      value={localState.totalPaidUSD}
                                    />
                                  )}
                                </Observer>
                              </div>
                            </div>
                          </>
                        )
                      }
                    </Observer>
                    <Divider>
                      <div className="flex items-center">
                        <img
                          alt="mx-flag"
                          src={MXFlag}
                          className="h-3 w-5 mr-2 rounded-sm shadow"
                        />{' '}
                        Totales MXN
                      </div>
                    </Divider>
                    <div className="grid grid-cols-4 gap-x-4">
                      <div>
                        <label
                          htmlFor="totalDividend_mxn"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Dividendos
                        </label>
                        <Observer>
                          {() => (
                            <CurrencyInput
                              readOnly
                              name="totalDividend_mxn"
                              id="totalDividend_mxn"
                              value={localState.totalDividendMXN}
                            />
                          )}
                        </Observer>
                      </div>
                      <div>
                        <label
                          htmlFor="totalRetention_mxn"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Retención
                        </label>

                        <Observer>
                          {() => (
                            <CurrencyInput
                              readOnly
                              name="totalRetention_mxn"
                              id="totalRetention_mxn"
                              value={localState.totalRetentionMXN}
                            />
                          )}
                        </Observer>
                      </div>

                      <div>
                        <label
                          htmlFor="otherFees_mxn"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Comisiones
                        </label>

                        <Observer>
                          {() =>
                            localState.dividendCurrency === 'USD' ? (
                              <CurrencyInput
                                name="otherFees_mxn"
                                id="otherFees_mxn"
                                readOnly
                                value={localState.otherFeesMXN}
                              />
                            ) : (
                              <CurrencyInput
                                name="otherFees_mxn"
                                id="otherFees_mxn"
                                onChange={localState.setOtherFeesMXN}
                                value={localState.otherFeesMXN}
                              />
                            )
                          }
                        </Observer>
                      </div>

                      <div>
                        <label
                          htmlFor="totalPaid_mxn"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pagado
                        </label>

                        <Observer>
                          {() => (
                            <CurrencyInput
                              readOnly
                              name="totalPaid_mxn"
                              id="totalPaid_mxn"
                              value={localState.totalPaidMXN}
                            />
                          )}
                        </Observer>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="mt-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Enviar
                  </button>
                  <Link
                    to="./.."
                    className="ml-4 text-indigo-600 text-sm hover:text-indigo-900"
                  >
                    cancelar
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
