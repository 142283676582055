import serialize from 'form-serialize';
import { useContext, useLayoutEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { pki } from 'node-forge';
import StoresContext from '../../../Stores';
import useAPI from '../../../hooks/useAPI';

function CreateCompany() {
  let certificate;
  let privateKey;
  let decryptedPrivateKey;

  const { companies, users } = useContext(StoresContext);
  const params = useParams();

  const navigate = useNavigate();

  const { data, isLoading, isEmpty, error, mutate } = useAPI(
    params.companyId ? `/companies/${params.companyId}` : null
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const formData = serialize(evt.currentTarget, { hash: true });

    const { authorizedUsers } = formData;
    const newAuthorizedUsers = [];

    if (authorizedUsers) {
      Object.keys(authorizedUsers).forEach((authorizedUser) => {
        newAuthorizedUsers.push(authorizedUser.split("'")[1]);
      });

      formData.authorizedUsers = newAuthorizedUsers.join(',');
    }

    if (!params.companyId) {
      await companies.create({
        ...formData,
        certificate,
        privateKey: decryptedPrivateKey
      });
    } else {
      await companies.patch(params.companyId, {
        ...formData,
        certificate,
        privateKey: decryptedPrivateKey
      });
      mutate();
    }
    // navigate(`/companies/view/${company.id}`);
    navigate('/companies');
    return false;
  };

  const handleCertificateSelect = async (evt) => {
    const fileHandle = evt.currentTarget.files[0];
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      certificate = e.target.result;
    };

    fileReader.readAsDataURL(fileHandle);
  };

  const handlePrivateKeySelect = async (evt) => {
    const fileHandle = evt.currentTarget.files[0];
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      if (e.target.result) {
        [, privateKey] = e.target.result.split(';base64,');
      }
    };

    fileReader.readAsDataURL(fileHandle);
  };

  const createDecryptedPrivateKey = async (evt) => {
    const password = evt.currentTarget.value;
    if (password && privateKey) {
      const encryptedPKCS8PrivateKeyPEM = `-----BEGIN ENCRYPTED PRIVATE KEY-----\r\n${privateKey}\r\n-----END ENCRYPTED PRIVATE KEY-----`;
      decryptedPrivateKey = pki.privateKeyToPem(
        pki.decryptRsaPrivateKey(encryptedPKCS8PrivateKeyPEM, password)
      );
    }
  };

  useLayoutEffect(() => {
    users.fetchPublic();
  }, [users]);

  return (
    <form onSubmit={handleSubmit} className="h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <h1 className="mt-4 text-2xl font-semibold text-gray-900">
          Crear Empresa emisora
        </h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-6">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Certificado & Clave de sellos
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Del certificado se tomara el RFC, CURP y RFC del representante
                  legal y la razón social. La Clave privada se usara para sellar
                  los datos generales en los CFDI&apos;s
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="certificate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Certificado
                    </label>
                    <div className="mt-1">
                      <input
                        type="file"
                        name="certificate"
                        id="certificate"
                        onChange={handleCertificateSelect}
                        className=""
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="privateKey"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Clave privada
                    </label>
                    <div className="mt-1">
                      <input
                        type="file"
                        name="privateKey"
                        id="privateKey"
                        onChange={handlePrivateKeySelect}
                        className=""
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contrase&ntilde;a de la clave privada
                    </label>
                    <div className="mt-1">
                      <input
                        type="password"
                        onChange={createDecryptedPrivateKey}
                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Informacion adicional
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Aqui se configuran los usuarios que tendran acceso a la
                  empresa y otros datos necesarios para timbrar.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    {/* <div className="col-span-6 sm:col-span-4">
                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                      Correo electrónico del agente
                    </label>
                    <input
                      type="email"
                      name="agentEmail"
                      id="agentEmail"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                    />
                  </div> */}

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="zipCode"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Codigo postal de domicilio fiscal
                      </label>
                      <input
                        type="text"
                        name="zipCode"
                        id="zipCode"
                        maxLength={5}
                        defaultValue={data && data.zipCode}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="taxRgime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Régimen fiscal
                      </label>
                      <input
                        type="text"
                        name="taxRegime"
                        id="taxRegime"
                        defaultValue={data && data.taxRegime}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="factureyaUser"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre usuario servicio factureya
                      </label>
                      <input
                        type="text"
                        name="factureyaUser"
                        id="factureyaUser"
                        defaultValue={data && data.factureyaUser}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="factureyaPassword"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contrase&ntilde;a de servicio factureya
                      </label>
                      <input
                        type="text"
                        name="factureyaPassword"
                        id="factureyaPassword"
                        defaultValue={data && data.factureyaPassword}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Quien tendra acceso a utilizar esta empresa:
                      </label>
                      <ul>
                        {users.publicItems.map((user) => (
                          <li key={user.id}>
                            <input
                              value={user.id}
                              type="checkbox"
                              id={`user-${user.id}`}
                              defaultChecked={
                                data &&
                                data.authorizedUsers
                                  .split(',')
                                  .includes(String(user.id))
                              }
                              name={`authorizedUsers['${user.id}']`}
                            />{' '}
                            <label
                              htmlFor={`user-${user.id}`}
                              className="ml-1 text-sm text-gray-700 font-medium"
                            >
                              {user.email}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="mt-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Enviar
                  </button>
                  <Link
                    to="/companies"
                    className="ml-4 text-indigo-600 text-sm hover:text-indigo-900"
                  >
                    cancelar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default observer(CreateCompany);
