import { makeAutoObservable } from 'mobx';

class Modals {
  current = null;

  constructor() {
    makeAutoObservable(this);
  }

  create = (current) => {
    this.current = current;
  };

  close = () => {
    this.current = null;
  };
}

export default Modals;
