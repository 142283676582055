import { action, makeAutoObservable } from 'mobx';

const DEFAULT_STATE = {
  currentModal: null
};

class ModalsStore {
  state = { ...DEFAULT_STATE };

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentModal = action((modal) => {
    this.state.currentModal = modal;
  });

  reset = action(() => {
    this.state = { ...DEFAULT_STATE };
  });
}

export default ModalsStore;
