import { Link, useNavigate, useParams } from 'react-router-dom';

import Decimal from 'decimal.js';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPINew from '../../../hooks/useAPINew';
import { MenuConfig } from '../../../Components/ActionMenu';
import modals from '../../../utils/modals';
import http from '../../../Services/HTTP';
import NumberFormat from '../../../Primitives/NumberFormat';
import { getFormData } from '../../../Modals';
import DateTime from '../../../Components/DateTime';

const translatedCells = {
  buy: 'compra',
  sell: 'venta',
  'transfer+': 'traspaso +',
  'transfer-': 'traspaso -',
  'donation+': 'donación +',
  'donation-': 'donación -'
};

const footerSum = (a, b) => {
  if (b.type === 'buy') {
    return a.plus(b.quantity);
  }

  if (b.type === 'sell') {
    return a.minus(b.quantity);
  }

  return 0;
};

const plusTypes = ['buy', 'transfer+', 'donation+'];
const minusTypes = ['sell', 'transfer-', 'donation-'];

const footerSum2 = (a, b) => {
  if (plusTypes.includes(b.type)) {
    return a.plus(new Decimal(b.quantity).times(b.unitPrice));
  }

  if (minusTypes.includes(b.type)) {
    return a.minus(new Decimal(b.quantity).times(b.unitPrice));
  }

  return 0;
};

const columns = [
  {
    key: 'recordDate',
    label: 'Fecha de registro',
    sortable: true,
    type: 'date',
    filterable: true,
    template: (cell) => <DateTime time={false}>{cell}</DateTime>
  },
  {
    key: 'type',
    label: 'TIPO',
    sortable: true,
    template: (cell) => translatedCells[cell]
  },
  {
    key: 'quantity',
    label: 'Cantidad',
    type: 'number',
    sortable: true,
    footerSum,
    template: (cell) => <NumberFormat>{cell}</NumberFormat>
  },
  {
    key: 'unitPrice',
    label: 'Precio unitario',
    type: 'number',
    sortable: true,
    template: (cell) => <NumberFormat>{cell}</NumberFormat>
  },
  {
    key: 'totalPrice',
    label: 'Monto total',
    type: 'number',
    sortable: true,
    footerSum: footerSum2,
    template: (cell) => <NumberFormat>{cell}</NumberFormat>
  }
];

const deleteAction = async ({ row, clients, mutate }) => {
  const confirmed = await modals.confirm(
    'Confirmar eliminar registro tipo Movimiento',
    'Estas seguro que deseas borrar este Movimiento?'
  );
  if (confirmed) {
    await http.delete(`/stocks/stockrecords/${row.id}`);
    mutate();
  }
};

const movementForm = [
  {
    key: 'name',
    type: 'text',
    label: 'Nombre de la acción'
  },
  {
    key: 'symbol',
    type: 'text',
    label: 'Simbolo de la acción'
  },
  {
    key: 'recordDate',
    type: 'date',
    label: 'Fecha de registro'
  },
  {
    key: 'type',
    type: 'radio',
    options: [
      { label: 'compra', value: 'buy' },
      { label: 'venta', value: 'sell' },
      { label: 'traspaso +', value: 'transfer+' },
      { label: 'traspaso -', value: 'transfer-' },
      { label: 'donación +', value: 'donation+' },
      { label: 'donación -', value: 'donation-' }
    ],
    label: 'Tipo'
  },
  {
    key: 'quantity',
    type: 'number',
    label: 'Cantidad'
  },
  {
    key: 'unitPrice',
    label: 'Precio unitario',
    type: 'text'
  }
];

const editAction = async ({ row, clientId, portfolioId, mutate }) => {
  const { canceled, data } = await getFormData(
    'Editar registro',
    'Revisa y cambia los datos de este registro de movimiento',
    movementForm,
    row
  );

  if (!canceled && data) {
    await http.patch(
      `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords/${row.id}`,
      data
    );
    mutate();
  }
};

const rowMenuConfig = new MenuConfig(
  [
    {
      key: 'edit',
      label: 'Editar',
      onClick: editAction
    },

    {
      key: 'delete',
      label: 'Borrar',
      onClick: deleteAction
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function CreateRecordButton({ forceName, forceSymbol }) {
  if (forceName && forceSymbol) {
    return (
      <LinkAddButton
        to={`./../create?forceName=${forceName}&forceSymbol=${forceSymbol}`}
      >
        Agregar Registro
      </LinkAddButton>
    );
  }
  return <LinkAddButton to="./../create">Agregar Registro</LinkAddButton>;
}

function StockRecordDetails() {
  const navigate = useNavigate();

  const { clientId, portfolioId, stockRecordId } = useParams();

  const [stockRecordDetail, isLoadingStockRecordDetail, isStockRecordEmpty] =
    useAPINew(
      `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords/${stockRecordId}/detail`
    );

  const [
    movements,
    isLoadingMovements,
    isMovementsEmpty,
    isMovementsError,
    mutate
  ] = useAPINew(
    `/stocks/${clientId}/portfolios/${portfolioId}/stockrecords/${stockRecordId}`
  );

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ row, navigate, mutate, clientId, portfolioId });

  const showTable = !isLoadingMovements && !isMovementsEmpty;

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl={`/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords`}
        title={`Movimientos ${stockRecordDetail?.name || ''}`}
        description={
          isLoadingStockRecordDetail
            ? 'cargando'
            : `Esta viendo los movimientos de la accion ${stockRecordDetail.name} en el portfafolio ${stockRecordDetail.portfolioName} del cliente ${stockRecordDetail.clientName}`
        }
      >
        {!isLoadingMovements && !isMovementsEmpty && stockRecordDetail && (
          <>
            <Link
              to="./dividends"
              className="text-indigo-600 font-medium mr-4 relative bottom-1 hover:underline cursor-pointer hover:text-indigo-700"
            >
              Dividendos
            </Link>
            <CreateRecordButton
              forceName={stockRecordDetail.name}
              forceSymbol={stockRecordDetail.symbol}
            />
          </>
        )}
      </TablePageHeading>

      {isLoadingMovements && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {isMovementsEmpty && stockRecordDetail && (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: 50 }}
        >
          <CreateRecordButton />
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={movements}
          rowMenuConfig={rowMenuConfig}
          rowKey="id"
        />
      )}
    </div>
  );
}

export default StockRecordDetails;
