import { useNavigate, useParams } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import Button from '../../../Primitives/Button';
import importAccountStatement from '../helpers/importAccountStatement';
import { confirm, getFormData } from '../../../Modals';
import http from '../../../Services/HTTP';
import NumberFormat from '../../../Primitives/NumberFormat';

const movementForm = [
  {
    key: 'date',
    label: 'Fecha del movimiento',
    type: 'date'
  },
  {
    key: 'uniqueTransactionId',
    label: 'Clave de rastreo o número de referencia',
    type: 'text'
  },
  {
    key: 'movement',
    label: 'Cliente o Proveedor',
    type: 'text'
  },
  {
    key: 'concept',
    label: 'Concepto',
    type: 'text'
  },
  {
    key: 'withdrawalAmount',
    label: 'Retiro (MXN)',
    type: 'number',
    customProps: {
      pattern: '^d+(?:.d{1,2})?$',
      min: '0',
      step: '0.01'
    }
  },
  {
    key: 'depositAmount',
    label: 'Deposito (MXN)',
    type: 'number',
    customProps: {
      pattern: '^d+(?:.d{1,2})?$',
      min: '0',
      step: '0.01'
    }
  }
];

const columns = [
  {
    key: 'date',
    label: 'Fecha',
    type: 'date',
    sortable: true,
    filterable: true
  },
  {
    key: 'withdrawalAmount',
    label: 'Retiro',
    type: 'number',
    footerSum: true,
    template: (cell, row) =>
      cell ? (
        <CurrencyFormat
          value={cell}
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          // suffix=" MXN"
          displayType="text"
          className="text-pink-500 font-semibold"
        />
      ) : null
  },
  {
    key: 'depositAmount',
    label: 'Deposito',
    type: 'number',
    footerSum: true,
    template: (cell, row) =>
      cell ? (
        <CurrencyFormat
          value={cell}
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          // suffix=" MXN"
          displayType="text"
          className="text-green-500 font-semibold"
        />
      ) : null
  },
  {
    key: 'balanceAmount',
    label: 'Saldo',
    type: 'number',
    footerSum: false,
    template: (cell, row) =>
      cell ? (
        <CurrencyFormat
          value={cell}
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          // suffix=" MXN"
          displayType="text"
          className="text-gray-400 font-semibold"
        />
      ) : null
  },
  {
    key: 'movement',
    label: 'Cliente o Proveedor'
  },
  {
    key: 'uniqueTransactionId',
    label: 'Clave de rastreo o número de referencia'
  },
  {
    key: 'concept',
    label: 'Concepto',
    type: 'book',
    template: (cell) => <span className="text-xs text-gray-700">{cell}</span>
  }
];

const menuConfig = new MenuConfig(
  [
    {
      key: 'edit',
      label: 'Editar',
      onClick: async ({ row, mutate }) => {
        const { canceled, data } = await getFormData(
          'Editar movimiento',
          'Edita este movimiento',
          movementForm,
          row
        );

        if (!canceled && data) {
          Object.keys(data).forEach((key) => {
            if (data[key] === '') {
              delete data[key];
            }
          });

          await http.patch(`/smart/${row.id}?model=BankingRecord`, data);
          mutate();
        }
      }
    },
    {
      key: 'delete',
      label: 'Borrar',
      onClick: async ({ row, mutate }) => {
        const confirmed = await confirm(
          'Confirma que deseas borrar este Movimiento'
        );
        if (!confirmed) {
          return;
        }

        const response = await http.delete(
          `/smart?model=BankingRecord&id=${row.id}`
        );
        const data = await response.json();

        if (data?.success === true) {
          mutate();
        }
      }
    }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

function ImportBankAccountStatementButton({ companyId, portfolioId, mutate }) {
  const handleButtonClick = () => {
    importAccountStatement({
      companyId,
      portfolioId,
      mutate
    });
  };

  return <Button onClick={handleButtonClick}>Importar estado de cuenta</Button>;
}

function AddMovementButton({ portfolioId, mutate }) {
  const handleButtonClick = async () => {
    const { canceled, data } = await getFormData(
      'Crear movimiento',
      'Agrega un movimiento a este portafolio',
      movementForm,
      {
        date: new Date()
      }
    );

    if (!canceled && data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === '') delete data[key];
      });

      await http.post(`/smart/create?model=BankingRecord`, {
        BankingPortfolioId: portfolioId,
        ...data
      });

      mutate();
    }
  };

  return <Button onClick={handleButtonClick}>Crear Movimiento</Button>;
}

function MovementList() {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading, isEmpty, mutate } = useAPI(
    `/banking/companies/${params.companyId}/portfolios/${params.portfolioId}`
  );

  console.log(data);

  const { data: companyData } = useAPI(
    `/smart/query?model=Company&where={"id":"${params.companyId}"}`
  );

  const { data: portfolioData } = useAPI(
    `/smart/query?model=BankingPortfolio&where={"id":"${params.portfolioId}"}`
  );

  menuConfig.onClick = (onClick, row) =>
    onClick({ row, navigate, params, mutate });

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="./.."
        title="Movimientos"
        description="Aqui puedes ver los movimientos en este portafolio"
      >
        {/* <ImportBankAccountStatementButton
          companyId={params.companyId}
          portfolioId={params.portfolioId}
          mutate={mutate}
        /> */}
        <AddMovementButton
          companyId={params.companyId}
          portfolioId={params.portfolioId}
          mutate={mutate}
        />
      </TablePageHeading>

      <div className="flex space-x-4">
        <h1 className="text-2xl text-gray-500">
          {companyData && companyData[0].name}
        </h1>
        <h1 className="text-md text-gray-400 mt-2">
          {portfolioData && portfolioData[0].name}
        </h1>
        <h1 className="text-md text-gray-400 mt-2">
          Saldo inicial:{' '}
          <NumberFormat>
            {portfolioData && portfolioData[0].initialBalance}
          </NumberFormat>
        </h1>
      </div>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {!isEmpty && data && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={menuConfig}
          rowKey="id"
          hover
        />
      )}
    </div>
  );
}

export default MovementList;
