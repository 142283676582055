import { stores } from '../Stores';

import FormModal from './FormModal';
import ConfirmationModal from './ConfirmationModal';
import PromptModal from './PromptModal';

const { modalsStore } = stores;

export const getFormData = async (title, description, fields, values) =>
  new Promise((resolve, reject) => {
    modalsStore.setCurrentModal(
      <FormModal
        resolve={resolve}
        reject={reject}
        fields={fields}
        title={title}
        values={values}
        description={description}
      />
    );
  });

export const confirm = (title, description) =>
  new Promise((resolve, reject) => {
    modalsStore.setCurrentModal(
      <ConfirmationModal
        resolve={resolve}
        reject={reject}
        title={title}
        description={description}
      />
    );
  });

export const prompt = (title, description, fields, values) =>
  new Promise((resolve) => {
    modalsStore.setCurrentModal(
      <PromptModal
        title={title}
        resolve={resolve}
        description={description}
        fields={fields}
        values={values}
      />
    );
  });
