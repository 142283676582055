/* eslint-disable no-underscore-dangle */
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useContext, useEffect } from 'react';
import Table from '../../../Components/Table';
import TablePageHeading from '../../../Components/TablePageHeading';
import LinkAddButton from '../../../Components/LinkAddButton';
import useAPI from '../../../hooks/useAPI';
import { MenuConfig } from '../../../Components/ActionMenu';
import StoresContext from '../../../Stores';
import { confirm, getFormData } from '../../../Modals';
import http, { HTTP } from '../../../Services/HTTP';
import Button from '../../../Primitives/Button';
import DateTime from '../../../Components/DateTime';

const columns = [
  {
    key: 'date',
    label: 'Fecha',
    type: 'date',
    template: (cell) => <DateTime>{cell}</DateTime>,
    sortable: true
  },
  {
    key: 'solvingObligations',
    label: 'Para solver obglicaciones',
    sortable: true
  }
];

const rowMenuConfig = new MenuConfig(
  [
    // {
    //   key: 'delete',
    //   label: 'Borrar',
    //   onClick: async ({ row, mutate }) => {
    //     const confirmed = await confirm(
    //       'Confirmar eliminación de registro',
    //       'Seguro que usted desea borrar este registro?'
    //     );
    //     if (confirmed) {
    //       await http.delete(`/smart?model=TDC&id=${row.id}`);
    //       mutate();
    //     }
    //   }
    // }
    // {
    //   key: 'dividends',
    //   label: ({ row }) => `Dividendos ${row.name}`,
    //   onClick: ({ row, navigate, clientId, portfolioId }) =>
    //     navigate(
    //       `/stocks/clients/${clientId}/portfolios/${portfolioId}/stockrecords/${row.id}/dividends`
    //     )
    // }
  ],
  {
    validator: (validator, row) => validator({ row })
  }
);

// const createTDCRegisterForm = [
//   { key: 'date', label: 'Fecha', type: 'text', required: true },
//   // {
//   //   key: 'determined',
//   //   label: 'Determinado',
//   //   type: 'text',
//   //   required: true
//   // },
//   // {
//   //   key: 'publicationDOF',
//   //   label: 'Publicado DOF',
//   //   type: 'text',
//   //   required: true
//   // },
//   {
//     key: 'solvingObligations',
//     label: 'Para solver obligaciones',
//     type: 'text',
//     required: true
//   }
// ];

function TDCS() {
  const order = escape('[["date", "DESC"]]');
  const { data, isLoading, isEmpty, mutate } = useAPI(
    `/smart/query?model=TDC&order=${order}`
  );

  rowMenuConfig.onClick = (onClick, row) => onClick({ row, mutate });

  const showTable = !isLoading && !isEmpty;

  // const handleCreateButtonClick = async () => {
  //   const { data: newTDCRecordData, canceled } = await getFormData(
  //     'Agregar tipo de cambio',
  //     'llena los campos y presiona enviar para agregar un nuevo registro',
  //     createTDCRegisterForm
  //   );

  //   if (!canceled) {
  //     await http.post('/smart/create?model=TDC', newTDCRecordData);
  //     mutate();
  //   }
  // };

  // const handlePaste = (evt) => {
  //   const paste = (evt.clipboardData || window.clipboardData).getData('text');
  //   console.log(paste);
  // };

  // useEffect(() => {
  //   document.addEventListener('paste', handlePaste);

  //   return () => {
  //     document.removeEventListener('paste', handlePaste);
  //   };
  // });

  const handleFileChange = (evt) => {
    const [xlsFileHandle] = evt.target.files;

    const fileReader = new FileReader();

    fileReader.addEventListener('load', async () => {
      await http.post('/stocks/tdcs/import', {
        xlsFileContent: fileReader.result
      });

      mutate();
    });

    fileReader.readAsDataURL(xlsFileHandle);
  };

  return (
    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
      <TablePageHeading
        backUrl="/"
        title="Tipo de Cambio"
        description={
          isLoading ? (
            'cargando'
          ) : (
            <>
              El tipoCambio.xls se puede exportar/descargar desde{' '}
              <a
                href="https://www.banxico.org.mx/tipcamb/main.do?page=tip&idioma=sp"
                target="_blank"
                className="underline font-semibold"
                rel="noreferrer"
              >
                aqui
              </a>
              .
            </>
          )
        }
      >
        <span className="text-sm">
          <label htmlFor="importtdcfile" className="text-gray-700 mr-4">
            Subir tipoCambio.xls
          </label>
          <input id="importtdcfile" type="file" onChange={handleFileChange} />
        </span>
      </TablePageHeading>

      {isLoading && (
        <div className="flex justify-center w-full py-3" style={{ height: 50 }}>
          fetching
        </div>
      )}

      {showTable && (
        <Table
          columns={columns}
          rows={data}
          rowMenuConfig={rowMenuConfig}
          rowKey="id"
        />
      )}
    </div>
  );
}

export default TDCS;
