import { action, makeAutoObservable, runInAction } from 'mobx';
import HTTP from '../Services/HTTP';
import modals from '../utils/modals';

class Companies {
  items = [];
  isFetching = false;
  currentCompany = null;

  constructor() {
    makeAutoObservable(this);
  }

  setFetching = action((value) => {
    this.isFetching = value;
  });

  fetch = async () => {
    this.setFetching(true);
    runInAction(() => {
      this.items = [];
    });

    const response = await HTTP.get('/companies');
    const data = await response.json();

    runInAction(() => {
      this.items = data;
    });
    this.setFetching(false);
  };

  create = async (values) => {
    const response = await HTTP.post(`/companies`, values);
    const data = await response.json();

    return data;
  };

  patch = async (companyId, values) => {
    const response = await HTTP.patch(`/companies/${companyId}`, values);
    const data = await response.json();

    return data;
  };

  delete = (company) => async () => {
    const confirmed = await modals.confirm(
      'Confirma que deseas borrar esta Empresa',
      'Una vez confirmando, ya no puedes usarla.'
    );

    if (!confirmed) {
      return;
    }

    const response = await HTTP.delete(`/companies/${company.id}`);
    const data = await response.json();

    if (data?.success === true) {
      runInAction(() => {
        this.items.remove(company);
      });
    }
  };

  fetchById = async (companyId) => {
    this.setFetching(true);
    const response = await HTTP.get(`/companies/${companyId}`);
    const data = await response.json();

    runInAction(() => {
      this.currentCompany = data;
    });

    this.setFetching(false);

    return data;
  };

  resetCurrentCompany = action(() => {
    this.currentCompany = null;
  });
}

export default Companies;
