import serialize from 'form-serialize';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { useContext, useLayoutEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import http from '../../../Services/HTTP';
import StoresContext from '../../../Stores';

import { availableApps } from '../../AppSelector';

function CreateUser() {
  const { users } = useContext(StoresContext);

  const navigate = useNavigate();
  const params = useParams();

  const state = useLocalObservable(() => ({
    email: '',
    password: '',
    visibleApps: '',
    isAdmin: false,
    async fetchUser(userId) {
      const response = await http.get(`/users/${userId}`);
      const json = await response.json();
      state.email = json.email;
      state.password = json.password;
      state.visibleApps = json.visibleApps;
      state.isAdmin = json.isAdmin;
    },
    set: (key) => (evt) => {
      if (evt.target.type === 'checkbox') {
        state[key] = evt.target.checked;
      } else {
        state[key] = evt.target.value;
      }
    },
    setList: (key, item) => (evt) => {
      if (evt.target.checked) {
        const tmp = state[key].split(',');
        tmp.push(item);
        state[key] = tmp.join(',');
      } else {
        const tmp = state[key]
          .split(',')
          .filter((filterItem) => filterItem !== item);
        state[key] = tmp.join(',');
      }
    }
  }));

  const createNewUser = async (evt) => {
    const data = serialize(evt.currentTarget, { hash: true });

    // we create a comma-separated string of apps which shall be visible to the user
    const { visibleApps } = data;
    const newVisibleApps = [];

    if (visibleApps) {
      Object.keys(visibleApps).forEach((availableApp) => {
        newVisibleApps.push(availableApp.split("'")[1]);
      });
      data.visibleApps = newVisibleApps.join(',');
    }

    if (data.isAdmin) {
      data.isAdmin = 1;
    }

    await users.create(data);
    navigate('/users');
    return false;
  };

  const patchExistingUser = async (evt) => {
    await users.patch(params.userId, state);
    navigate('/users');
    return false;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!params.userId) {
      createNewUser(evt);
    } else {
      patchExistingUser(evt);
    }
  };

  if (params.userId) {
    useLayoutEffect(() => {
      state.fetchUser(params.userId);

      return () => {};
    }, []);
  }

  return (
    <form onSubmit={handleSubmit} className="h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <h1 className="mt-4 text-2xl font-semibold text-gray-900">
          Crear usuario nuevo
        </h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-6">
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Datos del usuario
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Detalles del usuario y sus accesos
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block  font-medium text-gray-700"
                      >
                        Correo electrónico del usuario nuevo
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={state.email}
                        onChange={state.set('email')}
                        id="email"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="password"
                        className="block font-medium text-gray-700"
                      >
                        Contrase&ntilde;a {params.userId && 'nueva'}
                      </label>
                      <input
                        type="text"
                        name="password"
                        value={state.password}
                        onChange={state.set('password')}
                        id="password"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="password"
                        className="block font-medium text-gray-700"
                      >
                        Aplicaciones permitidas:
                      </label>
                      <ul>
                        {availableApps.map((app) => (
                          <li key={app.key} className="flex items-center py-1">
                            <input
                              type="checkbox"
                              id={`app-${app.key}`}
                              name={`visibleApps['${app.key}']`}
                              onChange={state.setList('visibleApps', app.key)}
                              checked={state.visibleApps.indexOf(app.key) > -1}
                            />{' '}
                            <label
                              htmlFor={`app-${app.key}`}
                              className="ml-2 text-sm text-gray-700"
                            >
                              {app.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="password"
                        className="block font-medium text-gray-700"
                      >
                        Tiene permisos tipo administrador?
                      </label>
                      <div className="flex items-center py-1">
                        <input
                          type="checkbox"
                          id="isAdmin"
                          name="isAdmin"
                          onChange={state.set('isAdmin')}
                          checked={state.isAdmin}
                        />{' '}
                        <label
                          htmlFor="isAdmin"
                          className="ml-2 text-sm text-gray-700"
                        >
                          Si
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="mt-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {params.userId ? 'Guardar' : 'Enviar'}
                  </button>
                  <Link
                    to="/users"
                    className="ml-4 text-indigo-600 text-sm hover:text-indigo-900"
                  >
                    cancelar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default observer(CreateUser);
